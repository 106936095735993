/** Import react/libraries section **/
import React, { useEffect, useState, useCallback } from 'react';

/** Import helpers section **/

/** Import services section **/
import { getGroupsSelectService } from '../../api/services/groups-services';
import { getPeriodsSelectService } from '../../api/services/periods-services';
import { getDashboardService } from '../../api/services/dashboard-services';

/** Import resources section **/

/** Import component section **/
import DashboardView from './DashboardView';

const DashboardComponent = () => {
  const [groups, setGroups] = useState([]);
  const [selectedGroupId, setSelectedGroupId] = useState(null);
  const [periods, setPeriods] = useState([]);
  const [selectedPeriodId, setSelectedPeriodId] = useState(null);
  const [dashboard, setDashboard] = useState(null);
  const [loading, setLoading] = useState(false);

  const getGroups = useCallback(() => {
    setLoading(true);
    getGroupsSelectService()
      .then((response) => {
        const groupsArr = response?.groups;
        if (groupsArr) {
          setGroups(groupsArr);
        }
      })
      .catch((error) => {
        console.error('Error requesting groups list: ', error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const getPeriods = useCallback((groupId) => {
    if (!groupId) {
      setPeriods([]);
      return;
    }
    setLoading(true);
    setPeriods([]);
    getPeriodsSelectService({ id: groupId })
      .then((response) => {
        const periodsArr = response?.periods;
        if (periodsArr) {
          setPeriods(periodsArr);
        }
      })
      .catch((error) => {
        console.error('Error requesting periods list: ', error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const getDashboard = useCallback((periodId, groupId) => {
    if (!periodId || !groupId) {
      setDashboard([]);
      return;
    }
    let params = {
      period_id: periodId
    };

    setLoading(true);
    setDashboard([]);
    getDashboardService(params)
      .then((response) => {
        const dashboardArr = response?.dashboard;
        if (dashboardArr) setDashboard(dashboardArr);
      })
      .catch((error) => {
        console.error('Error requesting dashboard list: ', error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    getGroups();
  }, [getGroups]);

  const handleOnGroupChange = (group) => {
    const groupId = group?.value;
    setSelectedGroupId(groupId);
    getPeriods(groupId);
    setDashboard([]);
  };

  const handleOnPeriodChange = (period) => {
    const periodId = period?.value;
    setSelectedPeriodId(periodId);
    getDashboard(periodId, selectedGroupId);
  };

  const handleOnDownloadReport = () => {
    const periodId = selectedPeriodId;
    const API_URL = process.env.REACT_APP_BACK_URL + '/';

    if (periodId) window.open(API_URL + 'api/report/?id=' + periodId, 'doc');
  };

  return (
    <DashboardView
      loading={loading}
      data={dashboard}
      groups={groups}
      selectedGroupId={selectedGroupId}
      onGroupChange={handleOnGroupChange}
      periods={periods}
      selectedPeriodId={selectedPeriodId}
      onPeriodChange={handleOnPeriodChange}
      onDownloadReport={handleOnDownloadReport}
    />
  );
};

export default DashboardComponent;
