/** Import react/libraries section **/
import React from 'react';

/** Import helpers section **/

/** Import resources section **/

/** Import syles section **/
import './SignUpsModalStyles.scss';

/** Import component section **/
import ModalComponent from '../../../components/Modal';
// import MUITextField from '../../../components/inputs/MUITextField';
import MUISelect from '../../../components/inputs/MUISelect';

const SignUpsModalView = ({
  title,
  staff,
  companies,
  areas,
  onInputChange,
  onClose,
  onSave,
  disabled
}) => {
  return (
    <ModalComponent
      open={true}
      title={title}
      onClose={onClose}
      onConfirm={onSave}
      disabled={disabled}
    >
      <div className="signUpsModal">
        <MUISelect
          label="Compañía"
          defaultValue={staff?.company_id}
          items={companies}
          idSelector="id"
          selector="name"
          noSelectedLabel="Select"
          name="company_id"
          onChange={onInputChange}
        />
        <MUISelect
          label="Área"
          defaultValue={staff?.area_id}
          items={areas}
          idSelector="id"
          selector="title"
          noSelectedLabel="Select"
          name="area_id"
          onChange={onInputChange}
        />
      </div>
    </ModalComponent>
  );
};

export default SignUpsModalView;
