/** Import react/libraries section **/
import React from 'react';

/** Import helpers section **/

/** Import resources section **/

/** Import syles section **/
import './DealModalStyles.scss';

/** Import component section **/
import ModalComponent from '../../../components/Modal/ModalComponent';
import MUITextField from '../../../components/inputs/MUITextField';
import MUISelect from '../../../components/inputs/MUISelect';

const DealModalView = ({
  deal,
  areas,
  staffs,
  onInputChange,
  onClose,
  onSave,
  disabled
}) => {
  return (
    <ModalComponent
      open={true}
      title={deal?.id ? 'Editar acuerdo' : 'Agregar acuerdo'}
      element={deal}
      onClose={onClose}
      onConfirm={onSave}
      disabled={disabled}
      width={700}
    >
      <div className="dealmodal">
        <MUISelect
          label="Área"
          defaultValue={deal?.area_id}
          items={areas}
          idSelector="id"
          selector="title"
          noSelectedLabel="Select"
          name="area_id"
          id="area_id"
          onChange={onInputChange}
        />
        <MUISelect
          label="Colaborador"
          defaultValue={deal?.staff_id}
          items={staffs}
          idSelector="id"
          selector="user_name"
          noSelectedLabel="Select"
          name="staff_id"
          id="staff_id"
          onChange={onInputChange}
        />

        <MUITextField
          id="description"
          label="Descripción"
          type="text"
          name="description"
          defaultValue={deal?.description}
          onChange={onInputChange}
        />
      </div>
    </ModalComponent>
  );
};

export default DealModalView;
