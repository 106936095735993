/** Import react/libraries section **/
import React from 'react';

/** Import resources section **/
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';

/** Import helpers section **/
import './PeriodsStyles.scss';
import { GROUPS_ROUTE } from '../../config/URL_ROUTES';

/** Import component section **/
import PageTitle from '../../components/PageTitle/PageTitleComponent';
import DataTable from '../../components/DataTable/DataTableComponent';
import ActiveCell from '../../components/ActiveCell';

const PeriodsView = ({
  loading,
  title,
  list,
  onAdd,
  onEdit,
  onSwitchActive,
  onDelete
}) => {
  const ActionsCell = ({ row }) => {
    return (
      <div className="g-actions-cell">
        <EditIcon className="g-row-action-button" onClick={() => onEdit(row)} />
        <Divider orientation="vertical" flexItem />
        <DeleteIcon
          className="g-row-action-button delete"
          onClick={() => onDelete(row)}
        />
      </div>
    );
  };

  const tableColumns = [
    {
      name: 'id',
      selector: (row) => row?.id,
      sortable: true,
      center: true,
      omit: true
    },
    {
      name: 'Fecha inicio',
      selector: (row) => row?.start_date,
      sortable: true,
      center: true
    },
    {
      name: 'Fecha Fin',
      selector: (row) => row?.end_date,
      sortable: true,
      center: true
    },
    {
      name: 'Activo',
      selector: (row) => row?.active,
      cell: (row) => (
        <ActiveCell
          active={row?.active ? true : false}
          onSwitchActive={() => onSwitchActive(row)}
        />
      ),
      sortable: true,
      center: true
    },
    {
      name: '',
      cell: (row) => <ActionsCell row={row} />,
      ignoreRowClick: true,
      grow: 0
    }
  ];

  return (
    <>
      <PageTitle title={'Periodos del grupo ' + title} backUrl={GROUPS_ROUTE} />
      <Paper className="g-page-header periods_header">
        <Button onClick={onAdd} startIcon={<AddCircleIcon />}>
          Agregar periodo
        </Button>
      </Paper>

      <DataTable data={list} columns={tableColumns} loading={loading} />
    </>
  );
};

export default PeriodsView;
