/** Import react/libraries section **/
import React, { useMemo } from 'react';

/** Import resources section **/
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';

/** Import helpers section **/
import './DealsStyles.scss';

/** Import component section **/
import PageTitle from '../../components/PageTitle/PageTitleComponent';
import MUISelect from '../../components/inputs/MUISelect';
import FilterBy from '../../components/FilterBy/FilterBy';
import DataTable from '../../components/DataTable/DataTableComponent';
import { ActionAccess, HasPermission } from '../../access/AccessControl';
import { DEALS_EDIT_PERMISSION } from '../../config/APP_CONFIGS';

const DealView = ({
  loading,
  list,
  groups,
  selectedGroupId,
  onGroupChange,
  onFilterBy,
  onAdd,
  onEdit,
  onDelete
}) => {
  const hasPermission = HasPermission(DEALS_EDIT_PERMISSION);

  const ActionsCell = ({ row }) => {
    return (
      <div className="g-actions-cell">
        <EditIcon className="g-row-action-button" onClick={() => onEdit(row)} />
        <Divider orientation="vertical" flexItem />
        <DeleteIcon
          className="g-row-action-button delete"
          onClick={() => onDelete(row)}
        />
      </div>
    );
  };

  const tableColumns = useMemo(
    () => [
      {
        name: 'id',
        selector: (row) => row?.id,
        sortable: true,
        center: true,
        omit: true
      },
      {
        name: 'Propietario',
        selector: (row) => row?.owner_name,
        sortable: true
      },
      {
        name: 'Área',
        selector: (row) => row?.area_title,
        sortable: true
      },
      {
        name: 'Evaluador',
        selector: (row) => row?.staff_name,
        sortable: true
      },
      {
        name: 'Acuerdo',
        selector: (row) => row?.description,
        sortable: true,
        wrap: true,
        grow: 2
      },
      {
        name: '',
        cell: (row) => <ActionsCell row={row} />,
        ignoreRowClick: true,
        width: '140px',
        omit: !hasPermission
      }
    ],
    [hasPermission]
  );

  return (
    <>
      <PageTitle title="Acuerdos" />
      <Paper className="g-page-header deals_header">
        <div className="deals_header_filters">
          <MUISelect
            label="Grupo"
            defaultValue={selectedGroupId}
            items={groups}
            idSelector="id"
            selector="title"
            name="group"
            id="group"
            onChange={onGroupChange}
          />

          <FilterBy onChange={onFilterBy} width={180} />
        </div>
        <div className="deals_header_actions">
          <ActionAccess permissionsRequest={DEALS_EDIT_PERMISSION}>
            <Button
              onClick={onAdd}
              startIcon={<AddCircleIcon />}
              disabled={!selectedGroupId}
            >
              Agregar nuevo acuerdo
            </Button>
          </ActionAccess>
        </div>
      </Paper>

      <DataTable data={list} columns={tableColumns} loading={loading} />
    </>
  );
};

export default DealView;
