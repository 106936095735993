import request from '../api-connector';

export const getGroupsService = ({ id }) => {
	const serviceDef = {
		url: 'group/list',
		method: 'get',
		params: { id }
	};
	return request(serviceDef);
};

export const getGroupsSelectService = () => {
	const serviceDef = {
		url: 'group/list/select',
		method: 'get',
		params: {}
	};
	return request(serviceDef);
};

export const addGroupService = ({ title, company_id }) => {
	const serviceDef = {
		url: 'group/create',
		method: 'post',
		params: {
			title,
			company_id
		}
	};
	return request(serviceDef);
};

export const updateGroupService = ({ id, title, company_id }) => {
	const serviceDef = {
		url: 'group/update',
		method: 'post',
		params: {
			id,
			title,
			company_id
		}
	};
	return request(serviceDef);
};

export const deleteGroupsService = ({ id }) => {
	const serviceDef = {
		url: 'group/delete',
		method: 'post',
		params: { id }
	};
	return request(serviceDef);
};
