/** Import react/libraries section **/
import React from 'react';

/** Import helpers section **/

/** Import component section **/
import GraphView from './GraphView';

const GraphComponent = ({ data, title }) => {
  var labels = data.map((label) => label.title);
  var values = data.map((value) => value.value);

  const handleOnGraphDownload = (ref) => {
    const a = document.createElement('a');
    a.href = ref.current.toBase64Image();
    a.download = data?.title + '.png';
    a.click();
  };
  return (
    <GraphView
      title={title}
      labels={labels}
      values={values}
      onGraphDownload={handleOnGraphDownload}
    />
  );
};

export default GraphComponent;
