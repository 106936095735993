/** Import react/libraries section **/
import React from 'react';
import TextField from '@mui/material/TextField';

export const MUITextField = ({
  name,
  id,
  labelId,
  label,
  type = 'text',
  value,
  onChange,
  style,
  disabled,
  placeholder,
  color = 'secondary',
  styleText,
  disabledText,
  ...props
}) => {
  const handleOnChange = (e) => {
    if (!onChange) return;
    const inputValue = e.target.value;

    const returnValue = inputValue
      ? type === 'number'
        ? parseFloat(inputValue)
        : inputValue
      : null;

    onChange({ id: id, name: name, value: returnValue });
  };

  return (
    <div id={'MUI_' + id}>
      <TextField
        autoComplete="new"
        id={id}
        label={label}
        type={type}
        name={name}
        value={value}
        disabled={disabled}
        placeholder={placeholder}
        onChange={handleOnChange}
        style={{ width: '100%' }}
        focused
        {...props}
      />
    </div>
  );
};

export default MUITextField;
