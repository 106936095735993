/** Import react/libraries section **/
import React from 'react';

/** Import helpers section **/

/** Import resources section **/

/** Import syles section **/
import './StaffModalStyles.scss';

/** Import component section **/
import ModalComponent from '../../../components/Modal/ModalComponent';
// import MUITextField from '../../../components/inputs/MUITextField';
import MUISelect from '../../../components/inputs/MUISelect';

const StaffModalView = ({
	groupStaff,
	staff,
	onInputChange,
	onClose,
	onSave,
	disabled
}) => {
	// console.log(groupStaff);
	return (
		<ModalComponent
			open={true}
			title={
				groupStaff?.id ? 'Editar colaborador' : 'Agregar colaborador'
			}
			element={groupStaff}
			onClose={onClose}
			onConfirm={onSave}
			disabled={disabled}
		>
			<div className="staffModal">
				<MUISelect
					label="Staff"
					// defaultValue={groupStaff?.staff_id}
					items={staff}
					idSelector="staff_id"
					selector="first_name"
					noSelectedLabel="Select"
					name="staff_id"
					// id="staff_id"
					onChange={onInputChange}
				/>
			</div>
		</ModalComponent>
	);
};

export default StaffModalView;
