/** Import helpers section **/
import * as routes from './URL_ROUTES';

/** Import component section **/
import LoginComponent from '../views/Login';
import RegistrationComponent from '../views/Registration';
import EmailValidationComponent from '../views/EmailValidation';
import ChangePasswordRequestComponent from '../views/ChangePasswordRequest';
import ChangePasswordComponent from '../views/ChangePassword';

import UsersComponent from '../views/Users';
import PermissionsComponent from '../views/Permissions';
import RolePermisionsComponent from '../views/RolePermissions';

import SignupsComponent from '../views/Signups';
import CompaniesComponent from '../views/Companies';
import AreasComponent from '../views/Areas';
import GroupsComponent from '../views/Groups';
import GroupStaffsComponent from '../views/GroupStaffs';
import PeriodsComponent from '../views/Periods';
import DealsComponent from '../views/Deals';
import EvaluationsComponent from '../views/Evaluations';
import DashboardComponent from '../views/Dashboard';

/*********************************************************************
 ************************  ROUTES CONTENT  ***************************
 *********************************************************************/

export const PUBLIC_ROUTES = [
  {
    path: '/',
    component: LoginComponent
  },
  {
    path: routes.LOGIN_ROUTE,
    component: LoginComponent
  },
  {
    path: routes.REGISTRATION_ROUTE,
    component: RegistrationComponent
  },
  {
    path: routes.EMAIL_VALIDATION_ROUTE + '/:token',
    component: EmailValidationComponent
  },
  {
    path: routes.CHANGE_PASSWORD_REQUEST_ROUTE,
    component: ChangePasswordRequestComponent
  },
  {
    path: routes.CHANGE_PASSWORD_ROUTE + '/:token',
    component: ChangePasswordComponent
  }
];

export const PRIVATE_ROUTES = [
  {
    path: routes.DASHBOARD_ROUTE,
    component: DashboardComponent
  },
  {
    path: routes.DEALS_ROUTE,
    component: DealsComponent
  },
  {
    path: routes.EVALUATIONS_ROUTE,
    component: EvaluationsComponent
  },
  {
    path: routes.USERS_ROUTE,
    component: UsersComponent
  },
  {
    path: routes.PERMISSIONS_ROUTE,
    component: PermissionsComponent
  },
  {
    path: routes.ROLE_PERMISSIONS_ROUTE,
    component: RolePermisionsComponent
  },
  {
    path: routes.SIGNUPS_ROUTE,
    component: SignupsComponent
  },
  {
    path: routes.COMPANIES_ROUTE,
    component: CompaniesComponent
  },
  {
    path: routes.AREAS_ROUTE,
    component: AreasComponent
  },
  {
    path: routes.GROUPS_ROUTE,
    component: GroupsComponent
  },
  {
    path: routes.PERIODS_ROUTE + '/:id',
    component: PeriodsComponent
  },
  {
    path: routes.GROUP_STAFFS_ROUTE + '/:id',
    component: GroupStaffsComponent
  }
];
