/** Import react/libraries section **/
import React from 'react';
import { Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';

/** Import resources section **/
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';

/** Import styles section **/
import './RegistrationStyles.scss';

/** Import helpers section **/

/** Import component section **/

const RegistrationView = ({ onSubmit, onCancel, inProcess, registered }) => {
  const { register, handleSubmit } = useForm();

  const handleOnSubmit = (data) => {
    onSubmit(data);
  };

  return (
    <div className="registration">
      <Paper className="registration_container">
        <h3>Registrar usuario</h3>
        <form
          className="registration_container_form"
          onSubmit={handleSubmit(handleOnSubmit)}
        >
          <div className="registration_container_form_fields">
            <TextField
              label="Email"
              className="registration_container_form-email"
              id="email"
              name="email"
              type="text"
              variant="outlined"
              {...register('email', {
                required: true,
                message: 'Email required'
              })}
            />
            <span />
            <TextField
              label="Nombre"
              id="first_name"
              name="first_name"
              className="registration_container_form-firstName"
              type="text"
              variant="outlined"
              {...register('first_name', {
                required: true,
                message: 'Se requiere nombre'
              })}
            />
            <TextField
              label="Apellido"
              className="registration_container_form-lastName"
              id="lastName"
              name="last_name"
              type="text"
              variant="outlined"
              {...register('last_name', {
                required: true,
                message: 'Se requiere apellido'
              })}
            />
            <TextField
              label="Contraseña"
              id="password"
              className="registration_container_form-password"
              name="password"
              type="password"
              variant="outlined"
              {...register('password', {
                required: true,
                message: 'Se requiere la contraseña'
              })}
            />
            <TextField
              label="Confirmar contraseña"
              id="passwordConf"
              className="registration_container_form-passwordConf"
              name="password_confirmation"
              type="password"
              variant="outlined"
              {...register('password_confirmation', {
                required: true,
                message: 'Se requiere la contraseña'
              })}
            />
          </div>
          <div className="registration_container_form_bottom">
            <div className="registration_container_form_bottom-buttons">
              <Link to="/" underline="hover">
                <Button onClick={onCancel} className="g-button cancel">
                  Atrás
                </Button>
              </Link>

              <Button onClick={handleSubmit} type="submit">
                {inProcess === true ? 'Enviando...' : 'Registrar'}
              </Button>
            </div>
          </div>
        </form>
      </Paper>
    </div>
  );
};

export default RegistrationView;
