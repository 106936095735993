/** Import react/libraries section **/
import React from 'react';

/** Import helpers section **/

/** Import resources section **/

/** Import syles section **/
import './CompanyModalStyles.scss';

/** Import component section **/
import ModalComponent from '../../../components/Modal/ModalComponent';
import MUITextField from '../../../components/inputs/MUITextField';

const CompanyModalView = ({
  company,
  onInputChange,
  onClose,
  onSave,
  disabled
}) => {
  return (
    <ModalComponent
      open={true}
      title={company?.id ? 'Editar compañía' : 'Agregar compañía'}
      element={company}
      onClose={onClose}
      onConfirm={onSave}
      disabled={disabled}
    >
      <div className="companymodal">
        <MUITextField
          id="name"
          label="Nombre comercial"
          defaultValue={company?.name}
          type="text"
          name="name"
          onChange={onInputChange}
        />
      </div>
    </ModalComponent>
  );
};

export default CompanyModalView;
