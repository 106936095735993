/** Import react/libraries section **/
import React, { useState, useEffect, useCallback } from 'react';

/** Import helpers section **/
import { toast } from 'react-toastify';

/** Import resources section **/

/** Import services section **/
import {
  getSignupsService,
  validateSignupService,
  changeRoleUserService,
  deleteStaffSignupService
} from '../../api/services/signups-services';
import { getCompaniesService } from '../../api/services/companies-services';

/** Import component section **/
import SignupsView from './SignupsView';
import ModalComponent from '../../components/Modal';
import SignUpsModal from './SignUpsModal';

const staffModel = {
  id: null,
  area_id: null,
  company_id: null,
  user_id: null
};

function SignupsComponent() {
  const [inProcess, setInProcess] = useState(false);
  const [companies, setCompanies] = useState(null);
  const [selectedCompanyId, setSelectedCompanyId] = useState(null);
  const [signups, setSignups] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);
  const [selectedRows, setSelectedRows] = useState(null);
  const [clearRows, setClearRows] = useState(false);
  const [multipleDisabled, setMultipleDisabled] = useState(true);
  const [modalMode, setModalMode] = useState('');

  const getSignups = useCallback((companyId) => {
    setInProcess(true);
    getSignupsService({ id: companyId })
      .then((response) => {
        if (response?.users) setSignups(response?.users);
      })
      .catch((err) => {
        if (err?.response_status) toast.error("Can't load information");
        console.error('Error getting registrations: ', err);
      })
      .finally(() => {
        setInProcess(false);
      });
  }, []);

  const getCompanies = useCallback(() => {
    setInProcess(true);
    getCompaniesService()
      .then((response) => {
        const companies = response?.companies;
        if (companies) {
          setCompanies(companies);
        }
      })
      .catch((err) => {
        if (err?.response_status) toast.error("Can't load information");
        console.error('Error getting companies: ', err);
      })
      .finally(() => {
        setInProcess(false);
      });
  }, []);

  useEffect(() => {
    getSignups();
    getCompanies();
  }, [getSignups, getCompanies]);

  const handleOnCompanyChange = (company) => {
    const companyId = company?.value;
    setSelectedCompanyId(companyId);
    getSignups(companyId);
  };

  const handleOnModalClose = () => {
    clearSelection();
  };

  const handleOnUpdateList = (selectedRows) => {
    getSignups(selectedCompanyId);
    handleOnModalClose();
  };

  const handleOnValidate = (row) => {
    setSelectedItem(row);
    setModalMode('validate');
  };

  const handleOnValidateConfirm = () => {
    let params = {
      id: selectedItem.id,
      actual: selectedItem.validate
    };
    validateSignupService(params)
      .then((response) => {
        toast.success('Registro actualizado');
      })
      .catch((err) => {
        if (err?.response_status)
          toast.error('No se puede actualizar el registro');
        console.error('Error changing status: ', err);
      })
      .finally(() => {
        clearSelection();
        getSignups();
      });
  };

  const handleOnRolSwitch = (row) => {
    setSelectedItem(row);
    setModalMode('role');
  };

  const handleOnRolSwitchConfirm = () => {
    let params = {
      id: selectedItem.id
    };
    changeRoleUserService(params)
      .then((response) => {
        toast.success('Role was changed');
      })
      .catch((err) => {
        if (err?.response_status) toast.error("Error, can't change role");
        console.error('Error changing role: ', err);
      })
      .finally(() => {
        clearSelection();
        getSignups();
      });
  };

  const clearSelection = () => {
    setSelectedItem(null);
    setModalMode('');
  };

  const handleOnAdd = () => {
    setSelectedItem(Object.assign({}, staffModel));
    setModalMode('multiple');
  };

  const handleOnEdit = (item) => {
    let staff = Object.assign({}, staffModel);
    staff.user_id = item.id;
    staff.id = item?.staff?.id;
    staff.area_id = item?.staff?.area_id;
    staff.company_id = item?.staff?.area?.company_id;
    setSelectedItem(staff);
    setModalMode('edit');
  };

  const handleOnRowsSelectChange = ({ selectedRows }) => {
    setSelectedRows(selectedRows);
    if (selectedRows.length === 0) {
      setMultipleDisabled(true);
    } else {
      setMultipleDisabled(false);
    }
  };

  const handleToggledClearRows = () => {
    setClearRows(!clearRows);
  };

  const handleOnDelete = (item) => {
    setSelectedItem(item);
    setModalMode('delete');
  };

  const handleOnDeleteConfirm = () => {
    deleteStaffSignupService({ id: selectedItem?.staff.id })
      .then((response) => {
        toast.success('Staff deleted');
        getSignups();
      })
      .catch((err) => {
        if (err?.response_status) toast.error("Error, can't delete staff");
        console.error('Error changing status: ', err);
      })
      .finally(() => {
        clearSelection();
        handleOnModalClose();
      });
  };

  return (
    <>
      <SignupsView
        inProcess={inProcess}
        companies={companies}
        multipleDisabled={multipleDisabled}
        data={signups}
        selectedCompanyId={selectedCompanyId}
        onCompanyChange={handleOnCompanyChange}
        onAdd={handleOnAdd}
        onRowsSelectChange={handleOnRowsSelectChange}
        toggledClearRows={handleToggledClearRows}
        onValidate={handleOnValidate}
        onRolSwitch={handleOnRolSwitch}
        onDelete={handleOnDelete}
        onEdit={handleOnEdit}
      />
      {selectedItem && modalMode === 'validate' && (
        <ModalComponent
          open={true}
          onClose={handleOnModalClose}
          onConfirm={handleOnValidateConfirm}
          title="Validate registration"
        >
          {'¿Estás seguro de ' +
            (selectedItem.validate ? 'desactivar' : 'activar') +
            ' este registro?'}
        </ModalComponent>
      )}
      {selectedItem && modalMode === 'role' && (
        <ModalComponent
          open={true}
          onClose={handleOnModalClose}
          onConfirm={handleOnRolSwitchConfirm}
          title="Cambio de puesto"
        >
          {'¿Estás seguro de cambiar este usuario a ' +
            (selectedItem.rol ? 'participante' : 'director')}
        </ModalComponent>
      )}
      {selectedRows && modalMode === 'multiple' && (
        <SignUpsModal
          modalMode={modalMode}
          staffData={selectedItem}
          selectedRows={selectedRows}
          companies={companies}
          onClose={handleOnModalClose}
          onUpdateList={handleOnUpdateList}
        />
      )}
      {selectedItem && modalMode === 'edit' && (
        <SignUpsModal
          modalMode={modalMode}
          staffData={selectedItem}
          selectedRows={[]}
          companies={companies}
          onClose={handleOnModalClose}
          onUpdateList={getSignups}
        />
      )}
      {selectedItem && modalMode === 'delete' && (
        <ModalComponent
          open={true}
          onClose={handleOnModalClose}
          onConfirm={handleOnDeleteConfirm}
          title="Delete"
        >
          {`Estas seguro que quieres borrar el registro`}
        </ModalComponent>
      )}
    </>
  );
}

export default SignupsComponent;
