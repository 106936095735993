import request from '../api-connector';

export const getPeriodsService = ({ id }) => {
  const serviceDef = {
    url: 'period/list',
    method: 'get',
    params: { id }
  };
  return request(serviceDef);
};

export const getPeriodsSelectService = ({ id }) => {
  const serviceDef = {
    url: 'period/list/select',
    method: 'get',
    params: { id }
  };
  return request(serviceDef);
};

export const addPeriodService = ({ group_id, start_date, end_date }) => {
  const serviceDef = {
    url: 'period/create',
    method: 'post',
    params: {
      group_id,
      start_date,
      end_date
    }
  };
  return request(serviceDef);
};

export const updatePeriodService = ({ id, group_id, start_date, end_date }) => {
  const serviceDef = {
    url: 'period/update',
    method: 'post',
    params: {
      id,
      group_id,
      start_date,
      end_date
    }
  };
  return request(serviceDef);
};

export const deletePeriodsService = ({ id }) => {
  const serviceDef = {
    url: 'period/delete',
    method: 'post',
    params: { id }
  };
  return request(serviceDef);
};

export const changeActiveService = ({ id }) => {
  const serviceDef = {
    url: 'period/update/active',
    method: 'post',
    params: {
      id
    }
  };
  return request(serviceDef);
};
