/** Import react/libraries section **/
import React from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
} from 'chart.js';

/** Import resources section **/
import Paper from '@mui/material/Paper';
import FileDownloadIcon from '@mui/icons-material/FileDownload';

/** Import helpers section **/
import './DashboardStyles.scss';

/** Import component section **/
import PageTitle from '../../components/PageTitle/PageTitleComponent';
import MUISelect from '../../components/inputs/MUISelect';
import GraphComponent from './Graph';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const DealView = ({
  loading,
  data,
  groups,
  selectedGroupId,
  onGroupChange,
  periods,
  selectedPeriodId,
  onPeriodChange,
  onDownloadReport
}) => {
  return (
    <div className="dashboard">
      <PageTitle title="Tablero" />
      <Paper className="g-page-header dashboard_header">
        <div className="dashboard_header_filters">
          <MUISelect
            label="Grupo"
            defaultValue={selectedGroupId}
            items={groups}
            idSelector="id"
            selector="title"
            name="group"
            id="group"
            onChange={onGroupChange}
          />
          <MUISelect
            label="Periodo"
            defaultValue={selectedPeriodId}
            items={periods}
            idSelector="id"
            selector="range"
            name="period"
            id="period"
            onChange={onPeriodChange}
          />
          {selectedPeriodId && (
            <FileDownloadIcon
              className="g-row-action-button primary"
              onClick={onDownloadReport}
            />
          )}
        </div>
      </Paper>
      <div className="dashboard_content">
        {data && data.length > 0 ? (
          <div className="dashboard_content_graphs">
            <div className="dashboard_content_graphs_areas">
              {data.map((item, index) => (
                <GraphComponent
                  key={'dash_' + index}
                  data={item?.evaluations}
                  title={item?.title}
                />
              ))}
            </div>
            <div className="dashboard_content_graphs_global">
              <GraphComponent data={data} title={'Consolidado'} />
            </div>
          </div>
        ) : (
          <div>No hay datos para mostrar</div>
        )}
      </div>
    </div>
  );
};

export default DealView;
