/** Import react/libraries section **/
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

/** Import helpers section **/
import {
  addGroupService,
  updateGroupService
} from '../../../api/services/groups-services';

/** Import component section **/
import GroupModalView from './GroupModalView';

const GroupModalComponent = ({ data, companies, onClose, onUpdateList }) => {
  const [group, setGroup] = useState(data);
  const [dataComplete, setDataComplete] = useState(null);

  useEffect(() => {
    const newGroup = Object.assign({}, data);
    setGroup(newGroup);
  }, [data]);

  const onInputChange = (data) => {
    const { name, value } = data;
    setGroup((prevState) => ({
      ...prevState,
      [name]: value
    }));
  };

  useEffect(() => {
    if (!group) return;
    let validated = false;

    const title = group?.title?.trim();
    const companyId = group?.company_id;

    if (title && companyId) {
      validated = true;
    }

    setDataComplete(validated);
  }, [group]);

  const handleOnSave = () => {
    if (group.id) {
      updateGroup();
    } else {
      createGroup();
    }
  };

  const createGroup = () => {
    addGroupService(group)
      .then((response) => {
        toast.success('Grupo creado');
        onUpdateList(response);
      })
      .catch((error) => {
        toast.error('No se pudo crear el grupo');
      });
  };

  const updateGroup = () => {
    updateGroupService(group)
      .then((response) => {
        toast.success('Grupo actualizado');
        onUpdateList(response);
      })
      .catch((error) => {
        toast.error('No se pudo actualizar el grupo');
      });
  };

  return (
    <GroupModalView
      group={group}
      companies={companies}
      onInputChange={onInputChange}
      onClose={onClose}
      onSave={handleOnSave}
      disabled={!dataComplete}
    />
  );
};

export default GroupModalComponent;
