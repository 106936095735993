/** Import react/libraries section **/
import React, { useMemo } from 'react';

/** Import resources section **/
import Paper from '@mui/material/Paper';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';

/** Import helpers section **/
import './EvaluationsStyles.scss';

/** Import component section **/
import PageTitle from '../../components/PageTitle/PageTitleComponent';
import MUISelect from '../../components/inputs/MUISelect';
import FilterBy from '../../components/FilterBy/FilterBy';
import DataTable from '../../components/DataTable/DataTableComponent';
import { HasPermission } from '../../access/AccessControl';
import { DEALS_EDIT_PERMISSION } from '../../config/APP_CONFIGS';

const EvaluationsView = ({
  loading,
  list,
  groups,
  selectedGroupId,
  onGroupChange,
  periods,
  selectedPeriodId,
  onPeriodChange,
  onFilterBy,
  onAdd,
  onDownloadReport,
  onShowGraph,
  onEdit,
  onRecord,
  onDelete
}) => {
  const hasPermission = HasPermission(DEALS_EDIT_PERMISSION);

  const RecordsCell = ({ row }) => {
    return (
      <div className="evaluations_records">
        {row?.records.map((record, index) => (
          <span key={'tr_' + index} className="evaluations_records_item">
            <span>{'s' + (index + 1) + ':'}</span>
            <span>{(record?.value ? record?.value : 0) + '%'}</span>
          </span>
        ))}
      </div>
    );
  };

  const ActionsCell = ({ row }) => {
    return (
      <div className="g-actions-cell">
        <AssignmentTurnedInIcon
          className="g-row-action-button secondary"
          onClick={() => onRecord(row, selectedPeriodId)}
        />
      </div>
    );
  };

  const tableColumns = useMemo(
    () => [
      {
        name: 'id',
        selector: (row) => row?.id,
        sortable: true,
        center: true,
        omit: true
      },
      {
        name: 'Propietario',
        selector: (row) => row?.owner_name,
        sortable: true,
        center: true
      },
      {
        name: 'Área',
        selector: (row) => row?.area_title,
        sortable: true,
        center: true
      },
      {
        name: 'Evaluador',
        selector: (row) => row?.staff_name,
        sortable: true,
        center: true
      },
      {
        name: 'Acuerdo',
        selector: (row) => row?.description,
        sortable: true,
        center: true,
        wrap: true,
        grow: 2,
        style: { justifyContent: 'left' }
      },
      {
        name: 'Registros',
        cell: (row) => <RecordsCell row={row} />,
        ignoreRowClick: true,
        center: true,
        grow: 2.5
      },
      {
        name: '',
        cell: (row) => <ActionsCell row={row} />,
        ignoreRowClick: true,
        width: '140px',
        omit: !hasPermission
      }
    ],
    [hasPermission]
  );

  return (
    <>
      <PageTitle title="Evaluaciones" />
      <Paper className="g-page-header evaluations_header">
        <div className="evaluations_header_filters">
          <MUISelect
            label="Grupo"
            defaultValue={selectedGroupId}
            items={groups}
            idSelector="id"
            selector="title"
            name="group"
            id="group"
            onChange={onGroupChange}
          />
          <MUISelect
            label="Periodo"
            defaultValue={selectedPeriodId}
            items={periods}
            idSelector="id"
            selector="range"
            name="period"
            id="period"
            onChange={onPeriodChange}
          />
          <FilterBy onChange={onFilterBy} width={180} />
        </div>
        <div className="evaluations_header_actions"></div>
      </Paper>

      <DataTable data={list} columns={tableColumns} loading={loading} />
    </>
  );
};

export default EvaluationsView;
