/** Import react/libraries section **/
import React, { useEffect, useState, useCallback } from 'react';

/** Import helpers section **/

/** Import services section **/
import { getEvaluationsService } from '../../api/services/deals-services';
import { getAreasSelectService } from '../../api/services/areas-services';
import { getGroupsSelectService } from '../../api/services/groups-services';
import { getPeriodsSelectService } from '../../api/services/periods-services';

/** Import resources section **/

/** Import component section **/
import RecordModal from './RecordModal';
import EvaluationsView from './EvaluationsView';

const EvaluationsComponent = () => {
  const [groups, setGroups] = useState([]);
  const [selectedGroupId, setSelectedGroupId] = useState(null);
  const [periods, setPeriods] = useState([]);
  const [selectedPeriodId, setSelectedPeriodId] = useState(null);
  const [deals, setDeals] = useState([]);
  const [originalData, setOriginalData] = useState([]);

  const [areas, setAreas] = useState([]);

  const [selectedRow, setSelectedRow] = useState(null);
  const [modalMode, setModalMode] = useState(null);
  const [loading, setLoading] = useState(false);

  const getAreas = useCallback(() => {
    setLoading(true);

    getAreasSelectService({ id: null })
      .then((response) => {
        const areas = response?.areas;
        if (areas) {
          setAreas(areas);
        }
      })
      .catch((error) => {
        console.error('Error requesting areas list: ', error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const getGroups = useCallback(() => {
    setLoading(true);
    getGroupsSelectService()
      .then((response) => {
        const groupsArr = response?.groups;
        if (groupsArr) {
          setGroups(groupsArr);
        }
      })
      .catch((error) => {
        console.error('Error requesting groups list: ', error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const getPeriods = useCallback((groupId) => {
    if (!groupId) {
      setPeriods([]);
      return;
    }
    setLoading(true);
    getPeriodsSelectService({ id: groupId })
      .then((response) => {
        const periodsArr = response?.periods;
        if (periodsArr) {
          setPeriods(periodsArr);
        }
      })
      .catch((error) => {
        console.error('Error requesting groups list: ', error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const getDeals = useCallback((periodId, groupId) => {
    if (!periodId || !groupId) {
      setDeals([]);
      setOriginalData([]);
      return;
    }
    let params = {
      id: groupId,
      period_id: periodId
    };

    setLoading(true);
    getEvaluationsService(params)
      .then((response) => {
        const dealsArr = response?.evaluation;
        if (dealsArr) {
          setDeals(dealsArr);
          setOriginalData(dealsArr);
        }
      })
      .catch((error) => {
        console.error('Error requesting deals list: ', error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    getAreas();
    getGroups();
  }, [getAreas, getGroups]);

  const handleOnUpdateList = () => {
    getDeals(selectedPeriodId, selectedGroupId);
    handleOnModalClose();
  };

  const handleOnRecord = (row) => {
    setSelectedRow(Object.assign({}, row));
    setModalMode('record');
  };

  const handleOnModalClose = () => {
    setSelectedRow(null);
    setModalMode(null);
  };

  const handleOnGroupChange = (group) => {
    const groupId = group?.value;
    setSelectedGroupId(groupId);
    getPeriods(groupId);
  };

  const handleOnPeriodChange = (period) => {
    const periodId = period?.value;
    setSelectedPeriodId(periodId);
    getDeals(periodId, selectedGroupId);
  };

  const handleOnFilterBy = (filterVal) => {
    let filteredItems;
    const filteringText = filterVal?.toLowerCase();

    if (!filterVal || filteringText.length === 0) {
      filteredItems = [...originalData];
    } else {
      filteredItems = originalData.filter((row) => {
        const field1 = row.owner_name
          ?.toString()
          .toLowerCase()
          .includes(filteringText);
        const field2 = row?.area_title
          ?.toString()
          .toLowerCase()
          .includes(filteringText);
        const field3 = row?.staff_name
          ?.toString()
          .toLowerCase()
          .includes(filteringText);
        const field4 = row?.description
          ?.toString()
          .toLowerCase()
          .includes(filteringText);

        return field1 || field2 || field3 || field4;
      });
    }

    setDeals(filteredItems);
  };

  return (
    <>
      <EvaluationsView
        loading={loading}
        list={deals}
        groups={groups}
        selectedGroupId={selectedGroupId}
        onGroupChange={handleOnGroupChange}
        periods={periods}
        selectedPeriodId={selectedPeriodId}
        onPeriodChange={handleOnPeriodChange}
        onFilterBy={handleOnFilterBy}
        onRecord={handleOnRecord}
      />
      {selectedRow && modalMode === 'record' && (
        <RecordModal
          selectedPeriodId={selectedPeriodId}
          data={selectedRow}
          areas={areas}
          onClose={handleOnModalClose}
          onUpdateList={handleOnUpdateList}
        />
      )}
    </>
  );
};

export default EvaluationsComponent;
