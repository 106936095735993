/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

/** Import resources section **/
import TextField from '@mui/material/TextField';
import DatePicker from '@mui/lab/DatePicker';

/** Import helpers section **/
import { isValid, format } from 'date-fns';

export const MUIDateField = ({
  id,
  name,
  label,
  value,
  onChange,
  ...otherProps
}) => {
  const [date, setDate] = useState(null);

  const handleOnDateChange = (dateData) => {
    setDate(dateData);
    if (onChange) {
      const returnDate = isValid(dateData)
        ? format(dateData, 'yyy-MM-dd')
        : null;
      onChange({ value: returnDate, id: id, name: name });
    }
  };

  useEffect(() => {
    const valueDate = new Date(value + ' 00:00');
    const newDate = isValid(valueDate) ? valueDate : null;
    setDate(newDate);
  }, [value]);

  return (
    <div id={'MUI_' + id}>
      <DatePicker
        id={id}
        name={name}
        label={label}
        size="small"
        value={date}
        onChange={handleOnDateChange}
        renderInput={(params) => (
          <TextField {...params} focused sx={{ width: '100%' }} />
        )}
        {...otherProps}
      />
    </div>
  );
};

MUIDateField.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.any,
  onChange: PropTypes.func
};

export default MUIDateField;
