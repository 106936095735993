/** Import react/libraries section **/
import React, { useEffect, useState, useCallback } from 'react';

/** Import helpers section **/
import { toast } from 'react-toastify';
import {
  getCompaniesService,
  deleteCompanyService
} from '../../api/services/companies-services';

/** Import resources section **/

/** Import component section **/
import Modal from '../../components/Modal/ModalComponent';
import CompanyModal from './CompanyModal/CompanyModalComponent';
import CompaniesView from './CompaniesView';

const companyModel = {
  id: null,
  name: ''
};

const CompaniesComponent = () => {
  const [originalData, setOriginalData] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [modalMode, setModalMode] = useState(null);
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [loading, setLoading] = useState(false);

  const getCompanies = useCallback(() => {
    setLoading(true);
    getCompaniesService()
      .then((response) => {
        const companies = response?.companies;
        if (companies) {
          setCompanies(companies);
          setOriginalData(companies);
        }
      })
      .catch((error) => {
        console.error(
          'Companies component - Error requesting companies list: ',
          error
        );
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    getCompanies();
  }, [getCompanies]);

  const handleOnUpdateList = (selectedCompany) => {
    getCompanies();
    handleOnModalClose();
  };

  const handleOnFilterBy = (filterVal) => {
    let filteredItems;

    if (!filterVal || filterVal.length === 0) {
      filteredItems = [...originalData];
    } else {
      const filteringText = filterVal?.toLowerCase();

      filteredItems = originalData.filter((item) => {
        const field1 = item.name?.toString().toLowerCase();

        return field1.includes(filteringText);
      });
    }

    setCompanies(filteredItems);
  };

  const handleOnAddCompany = () => {
    setSelectedCompany(Object.assign({}, companyModel));
    setModalMode('company');
  };

  const handleOnEditCompany = (row) => {
    let item = Object.assign({}, row);
    setSelectedCompany(item);
    setModalMode('company');
  };

  const handleOnModalClose = () => {
    setSelectedCompany(null);
    setModalMode(null);
  };

  const handleOnDeleteCompany = (row) => {
    setSelectedCompany(row);
    setModalMode('delete');
  };

  const handleOnDeleteConfirm = () => {
    deleteCompanyService({ id: selectedCompany.id })
      .then(() => {
        getCompanies();
        toast.success('Companía borrada');
      })
      .catch((error) => {
        toast.error('Error, no se puede borrar al compañía.');
      })
      .finally(() => {
        setSelectedCompany(null);
        setModalMode(null);
      });
  };

  return (
    <>
      <CompaniesView
        loading={loading}
        companies={companies}
        onFilterBy={handleOnFilterBy}
        onAddCompany={handleOnAddCompany}
        onEditCompany={handleOnEditCompany}
        onDeleteCompany={handleOnDeleteCompany}
      />
      {selectedCompany && modalMode === 'company' && (
        <CompanyModal
          companyData={selectedCompany}
          onClose={handleOnModalClose}
          onUpdateList={handleOnUpdateList}
        />
      )}
      {selectedCompany && modalMode === 'delete' && (
        <Modal
          open={true}
          onClose={handleOnModalClose}
          onConfirm={handleOnDeleteConfirm}
          title="Borrar compañía"
        >
          {'¿Estas seguro que quieres borrar la compañía ' +
            selectedCompany.name +
            '?'}
        </Modal>
      )}
    </>
  );
};

export default CompaniesComponent;
