import request from '../api-connector';

export const getGroupStaffsService = ({ id }) => {
	const serviceDef = {
		url: 'group-staff/list',
		method: 'get',
		params: { id }
	};
	return request(serviceDef);
};
export const getStaffListByCompanyService = ({ id }) => {
	const serviceDef = {
		url: 'group-staff/list-staff-by-company',
		method: 'get',
		params: { id }
	};
	return request(serviceDef);
};

export const addGroupStaffService = ({ group_id, staff_id }) => {
	const serviceDef = {
		url: 'group-staff/create',
		method: 'post',
		params: {
			group_id,
			staff_id
		}
	};
	return request(serviceDef);
};

export const removeGroupStaffService = ({ id }) => {
	const serviceDef = {
		url: 'group-staff/remove',
		method: 'post',
		params: { id }
	};
	return request(serviceDef);
};
