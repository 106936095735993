/** Import react/libraries section **/
import React, { useEffect, useState, useCallback } from 'react';

/** Import helpers section **/
import { toast } from 'react-toastify';

/** Import services section **/
import {
  getDealsService,
  deleteDealService
} from '../../api/services/deals-services';
import { getAreasByGroupService } from '../../api/services/areas-services';
import { getGroupsSelectService } from '../../api/services/groups-services';

/** Import resources section **/

/** Import component section **/
import Modal from '../../components/Modal/ModalComponent';
import DealModal from './DealModal';
import DealView from './DealsView';

const DealModel = {
  id: null,
  description: '',
  area_id: null,
  staff_id: null
};

const DealsComponent = () => {
  const [groups, setGroups] = useState([]);
  const [selectedGroupId, setSelectedGroupId] = useState(null);
  const [deals, setDeals] = useState([]);
  const [originalData, setOriginalData] = useState([]);

  const [areas, setAreas] = useState([]);

  const [selectedRow, setSelectedRow] = useState(null);
  const [modalMode, setModalMode] = useState(null);
  const [loading, setLoading] = useState(false);

  const getAreas = useCallback((groupId) => {
    setLoading(true);

    getAreasByGroupService({ group_id: groupId })
      .then((response) => {
        const areas = response?.areas;
        if (areas) {
          setAreas(areas);
        }
      })
      .catch((error) => {
        console.error('Error requesting areas list: ', error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const getGroups = useCallback(() => {
    setLoading(true);
    getGroupsSelectService()
      .then((response) => {
        const groupsArr = response?.groups;
        if (groupsArr) {
          setGroups(groupsArr);
        }
      })
      .catch((error) => {
        console.error('Error requesting groups list: ', error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const getDeals = useCallback((groupId) => {
    if (!groupId) {
      setDeals([]);
      setOriginalData([]);
      return;
    }
    let params = {
      id: groupId
    };

    setLoading(true);
    getDealsService(params)
      .then((response) => {
        const dealsArr = response?.deals;
        if (dealsArr) {
          setDeals(dealsArr);
          setOriginalData(dealsArr);
        }
      })
      .catch((error) => {
        console.error('Error requesting deals list: ', error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    getGroups();
  }, [getGroups]);

  const handleOnUpdateList = () => {
    getDeals(selectedGroupId);
    handleOnModalClose();
  };

  const handleOnAdd = () => {
    setSelectedRow(
      Object.assign(DealModel, {
        group_id: selectedGroupId
      })
    );
    setModalMode('deal');
  };

  const handleOnEdit = (row) => {
    let dealItem = Object.assign({}, row);
    dealItem.area_id = row?.staff_area_id;
    dealItem.staff_id = row?.staff_id;
    setSelectedRow(dealItem);
    setModalMode('deal');
  };

  const handleOnModalClose = () => {
    setSelectedRow(null);
    setModalMode(null);
  };

  const handleOnDelete = (row) => {
    setSelectedRow(row);
    setModalMode('delete');
  };

  const handleOnDeleteConfirm = () => {
    deleteDealService({ id: selectedRow.id })
      .then(() => {
        getDeals();
        toast.success('Acuerdo borrado');
        handleOnUpdateList();
      })
      .catch((error) => {
        toast.error('Error, no se puede borrar el acuerdo.');
      })
      .finally(() => {
        setSelectedRow(null);
        setModalMode(null);
      });
  };

  const handleOnGroupChange = (group) => {
    const groupId = group?.value;
    setSelectedGroupId(groupId);
    getAreas(groupId);
    getDeals(groupId);
  };

  const handleOnFilterBy = (filterVal) => {
    let filteredItems;
    const filteringText = filterVal?.toLowerCase();

    if (!filterVal || filteringText.length === 0) {
      filteredItems = [...originalData];
    } else {
      filteredItems = originalData.filter((row) => {
        const field1 = row.owner_name
          ?.toString()
          .toLowerCase()
          .includes(filteringText);
        const field2 = row?.area_title
          ?.toString()
          .toLowerCase()
          .includes(filteringText);
        const field3 = row?.staff_name
          ?.toString()
          .toLowerCase()
          .includes(filteringText);
        const field4 = row?.description
          ?.toString()
          .toLowerCase()
          .includes(filteringText);

        return field1 || field2 || field3 || field4;
      });
    }

    setDeals(filteredItems);
  };

  return (
    <>
      <DealView
        loading={loading}
        list={deals}
        groups={groups}
        selectedGroupId={selectedGroupId}
        onGroupChange={handleOnGroupChange}
        onFilterBy={handleOnFilterBy}
        onAdd={handleOnAdd}
        onEdit={handleOnEdit}
        onDelete={handleOnDelete}
      />
      {selectedRow && modalMode === 'deal' && (
        <DealModal
          data={selectedRow}
          areas={areas}
          onClose={handleOnModalClose}
          onUpdateList={handleOnUpdateList}
        />
      )}
      {selectedRow && modalMode === 'delete' && (
        <Modal
          open={true}
          onClose={handleOnModalClose}
          onConfirm={handleOnDeleteConfirm}
          title="Borrar acuerdo"
        >
          {'¿Estas seguro que quieres borrar el acuerdo ' +
            selectedRow.description +
            '?'}
        </Modal>
      )}
    </>
  );
};

export default DealsComponent;
