/** Import react/libraries section **/
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import {
  addPeriodService,
  updatePeriodService
} from '../../../api/services/periods-services';

/** Import component section **/
import PeriodModalView from './PeriodModalView';

const PeriodModalComponent = ({ data, onClose, onUpdateList }) => {
  const [period, setPeriod] = useState(data);
  const [dataComplete, setDataComplete] = useState(null);

  const onInputChange = (data) => {
    const { name, value } = data;

    setPeriod((prevState) => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleOnSave = () => {
    if (period.id) {
      updatePeriod();
    } else {
      createPeriod();
    }
  };

  const createPeriod = () => {
    addPeriodService(period)
      .then((response) => {
        toast.success('Periodo creado');
        onUpdateList(response);
      })
      .catch((error) => {
        const err = error.response_status;
        switch (err) {
          case 409:
            toast.error('Error al crear, este staff ya existe');
            break;
          case 406:
            toast.error('Información faltante');
            break;
          default:
            toast.error('Error al crear staff');
            break;
        }
      });
  };

  const updatePeriod = () => {
    updatePeriodService(period)
      .then((response) => {
        toast.success('Periodo actualizado');
        onUpdateList(response);
      })
      .catch((error) => {
        const err = error.response_status;
        switch (err) {
          case 406:
            toast.error('Información faltante');
            break;
          default:
            toast.error('Error al crear periodo');
            break;
        }
      });
  };

  useEffect(() => {
    const newPeriod = Object.assign({}, data);
    setPeriod(newPeriod);
  }, [data]);

  useEffect(() => {
    if (!period) return;
    let validated = period?.start_date && period?.end_date;

    setDataComplete(validated);
  }, [period]);

  return (
    <PeriodModalView
      period={period}
      onInputChange={onInputChange}
      onClose={onClose}
      onSave={handleOnSave}
      disabled={!dataComplete}
    />
  );
};

export default PeriodModalComponent;
