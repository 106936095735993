import request from '../api-connector';

export const getAreasService = ({ id }) => {
  const serviceDef = {
    url: 'area/list',
    method: 'get',
    params: { id }
  };
  return request(serviceDef);
};
export const getAreasSelectService = ({ company_id }) => {
  const serviceDef = {
    url: 'area/list/select',
    method: 'get',
    params: { company_id }
  };

  return request(serviceDef);
};

export const getAreasByGroupService = ({ group_id }) => {
  const serviceDef = {
    url: 'area/group/select',
    method: 'get',
    params: { group_id }
  };
  return request(serviceDef);
};

export const addAreasService = ({ title, company_id }) => {
  const serviceDef = {
    url: 'area/create',
    method: 'post',
    params: {
      title,
      company_id
    }
  };
  return request(serviceDef);
};

export const updateAreasService = ({ id, title, company_id }) => {
  const serviceDef = {
    url: 'area/update',
    method: 'post',
    params: {
      id,
      title,
      company_id
    }
  };
  return request(serviceDef);
};

export const deleteAreasService = ({ id }) => {
  const serviceDef = {
    url: 'area/delete',
    method: 'post',
    params: { id }
  };
  return request(serviceDef);
};
