/** Import react/libraries section **/
import React from 'react';

/** Import helpers section **/

/** Import resources section **/

/** Import syles section **/
import './GroupModalStyles.scss';

/** Import component section **/
import ModalComponent from '../../../components/Modal/ModalComponent';
import MUITextField from '../../../components/inputs/MUITextField';
import MUISelect from '../../../components/inputs/MUISelect';

const GroupModalView = ({
  group,
  companies,
  onInputChange,
  onClose,
  onSave,
  disabled
}) => {
  return (
    <ModalComponent
      open={true}
      title={group?.id ? 'Editar grupo' : 'Agregar grupo'}
      element={group}
      onClose={onClose}
      onConfirm={onSave}
      disabled={disabled}
    >
      <div className="groupmodal">
        <MUITextField
          id="title"
          label="Nombre del grupo"
          defaultValue={group?.title}
          type="text"
          name="title"
          onChange={onInputChange}
        />
        <MUISelect
          label="Compañia"
          defaultValue={group?.company_id}
          items={companies}
          idSelector="id"
          selector="name"
          noSelectedLabel="Select"
          name="company_id"
          id="company_id"
          onChange={onInputChange}
        />
      </div>
    </ModalComponent>
  );
};

export default GroupModalView;
