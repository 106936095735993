/** Import react/libraries section **/
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

/** Import helpers section **/
import { updateRecordsService } from '../../../api/services/deals-services';

/** Import component section **/
import RecordModalView from './RecordModalView';

const RecordModalComponent = ({
  data,
  selectedPeriodId,
  onClose,
  onUpdateList
}) => {
  const [dealId, setDealId] = useState(data);
  const [records, setRecords] = useState([]);

  useEffect(() => {
    setDealId(data?.id);
    setRecords(data?.records);
  }, [data]);

  const handleOnRecordChange = (sequence, value) => {
    const newRecord = {
      sequence,
      value
    };
    const newRecordsArr = [...records];
    newRecordsArr[sequence - 1] = newRecord;
    setRecords(newRecordsArr);
  };

  const updateRecords = () => {
    const params = {
      id: dealId,
      period_id: selectedPeriodId,
      records
    };
    updateRecordsService(params)
      .then((response) => {
        toast.success('Registros actualizado');
        onUpdateList(response);
      })
      .catch((error) => {
        toast.error('No se pudo actualizar los registros');
      });
  };

  const handleOnSave = () => {
    updateRecords();
  };

  return (
    <RecordModalView
      records={records}
      onRecordChange={handleOnRecordChange}
      onClose={onClose}
      onSave={handleOnSave}
      selectedPeriodId={selectedPeriodId}
    />
  );
};

export default RecordModalComponent;
