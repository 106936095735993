/** Import react/libraries section **/
import React, { useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import {
  getStaffListByCompanyService,
  addGroupStaffService
} from '../../../api/services/group-staffs-services';

/** Import component section **/
import StaffModalView from './StaffModalView';

const StaffModalComponent = ({ data, onClose, onUpdateList }) => {
  const [groupStaff, setGroupStaff] = useState(data);
  const [staff, setStaff] = useState(null);
  const [dataComplete, setDataComplete] = useState(null);

  const onInputChange = (data) => {
    const { name, value } = data;
    setGroupStaff((prevState) => ({
      ...prevState,
      [name]: value
    }));
  };

  const getStaff = useCallback(() => {
    getStaffListByCompanyService({ id: groupStaff.group_id })
      .then((response) => {
        const staffs = response?.staffs;
        if (staffs) {
          setStaff(staffs);
        }
      })
      .catch((error) => {
        toast.error('Error al obtener el staff');
        console.error('Error requesting staff personel list: ', error);
      });
  }, [groupStaff.group_id]);

  const createGroupStaff = () => {
    addGroupStaffService(groupStaff)
      .then((response) => {
        toast.success('Creado');
        onUpdateList(response);
      })
      .catch((error) => {
        const err = error.response_status;
        switch (err) {
          case 409:
            toast.error('Error al crear, este staff ya existe');
            break;
          case 406:
            toast.error('Información faltante');
            break;
          default:
            toast.error('Error al crear staff');
            break;
        }
      });
  };
  useEffect(() => {
    getStaff();
  }, [getStaff]);

  useEffect(() => {
    if (!groupStaff) return;
    let validated = false;

    const staff_id = groupStaff?.staff_id;

    if (staff_id) {
      validated = true;
    }

    setDataComplete(validated);
  }, [groupStaff]);

  const handleOnSave = () => {
    createGroupStaff();
  };

  return (
    <StaffModalView
      groupStaff={groupStaff}
      staff={staff}
      onInputChange={onInputChange}
      onClose={onClose}
      onSave={handleOnSave}
      disabled={!dataComplete}
    />
  );
};

export default StaffModalComponent;
