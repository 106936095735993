/** Import react/libraries section **/
import React, { useMemo } from 'react';

/** Import helpers section **/

/** Import styles section **/
import './SignupsStyles.scss';

/** Import resources section **/

/** Import component section **/
import PageTitle from '../../components/PageTitle';
import DataTable from '../../components/DataTable';
import ActiveCell from '../../components/ActiveCell';
import MUISelect from '../../components/inputs/MUISelect';
import ActionsCell from '../../components/ActionsCell';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import MUICheckBox from '../../components/inputs/MUICheckBox';
import { Button, Paper } from '@mui/material';

const SignupsView = ({
  inProcess,
  selectedCompanyId,
  multipleDisabled,
  companies,
  onCompanyChange,
  data,
  onAdd,
  onValidate,
  onRolSwitch,
  onEdit,
  onDelete,
  onRowsSelectChange,
  toggledClearRows
}) => {
  const tableColumns = useMemo(
    () => [
      {
        name: 'id',
        selector: (row) => row?.company?.id,
        omit: true
      },
      {
        name: 'First Name',
        selector: (row) => row?.first_name,
        sortable: true
      },
      {
        name: 'Last name',
        selector: (row) => row?.last_name,
        sortable: true
      },
      {
        name: 'Email',
        selector: (row) => row?.email,
        sortable: true,
        wrap: true,
        grow: 2
      },
      {
        name: 'Area',
        selector: (row) => row?.staff?.area?.title,
        sortable: true
      },
      {
        name: 'Compañia',
        selector: (row) => row?.staff?.area?.company?.name,
        sortable: true
      },
      {
        name: 'Validated',
        selector: (row) => row?.validate,
        cell: (row) => (
          <ActiveCell
            active={row.active ? true : false}
            onSwitchActive={() => onValidate(row)}
          />
        ),
        sortable: true,
        center: true
      },
      {
        name: 'Director',
        selector: (row) => row?.role_id,
        cell: (row) => (
          <ActiveCell
            active={row.role_id === 3 ? true : false}
            onSwitchActive={() => onRolSwitch(row)}
          />
        ),
        sortable: true,
        center: true
      },
      {
        name: '',
        cell: (row) => (
          <ActionsCell
            onEdit={onEdit}
            onDelete={row.staff && onDelete}
            item={row}
          />
        ),
        center: true
      }
    ],
    [onValidate, onEdit, onDelete, onRolSwitch]
  );

  return (
    <>
      <PageTitle title="Registros" />
      <Paper className="signups_header groups_header">
        <div>
          <div className="groups_header_filters">
            <MUISelect
              label="Compañía"
              defaultValue={selectedCompanyId}
              items={companies}
              idSelector="id"
              selector="name"
              name="company"
              id="company"
              onChange={onCompanyChange}
            />
          </div>
        </div>
        <Button
          onClick={onAdd}
          startIcon={<AddCircleIcon />}
          disabled={multipleDisabled}
        >
          Asignar multiples registros
        </Button>
      </Paper>
      <DataTable
        data={data}
        columns={tableColumns}
        loading={inProcess}
        selectableRowsComponent={MUICheckBox}
        selectableRows
        onSelectedRowsChange={onRowsSelectChange}
      />
    </>
  );
};

export default SignupsView;
