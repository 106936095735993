/** Import react/libraries section **/
import React from 'react';
import ReactExport from 'react-export-excel';
import { useLocation } from 'react-router-dom';

/** Import helpers section **/
import { useTheme } from '@mui/material/styles';

import makeStyles from '@mui/styles/makeStyles';

/** Import resources section **/
import Paper from '@mui/material/Paper';
import ExcelIcon from './excel_icon.png';

/** Import component section **/
import DataTable from 'react-data-table-component';
import Loading from '../Loading';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const useStyles = makeStyles((theme) => ({
  tableContainer: {
    marginTop: '5px',
    height: 'auto',
    padding: '10px',
    borderColor: theme.palette.secondary.main
  },
  noData: {
    paddingTop: '8px',
    fontSize: '12px',
    color: '#808080'
  },
  exportButton: {
    height: '32px',
    position: 'absolute',
    zIndex: '10',
    bottom: '20px'
  }
}));

const customStyles = (theme) => ({
  rows: {
    style: {
      minHeight: '30px',
      backgroundColor: 'white',
      padding: '5px'
    }
  },
  headCells: {
    style: {
      paddingLeft: '8px',
      paddingRight: '8px',
      fontSize: '12px',
      fontWeight: 800,
      minHeight: '40px',
      color: theme.palette.light,
      backgroundColor: theme.palette.complementary3.main,
      textAlign: 'center'
    },
    activeSortStyle: {
      color: theme.palette.light,
      '&:focus': {
        outline: 'none'
      },
      '&:hover:not(:focus)': {
        color: theme.palette.light
      }
    },
    inactiveSortStyle: {
      color: theme.palette.light,
      '&:focus': {
        outline: 'none'
      },
      '&:hover:not(:focus)': {
        color: theme.palette.light
      },
      '&:active': {
        color: 'white'
      }
    }
  },
  cells: {
    style: {
      paddingLeft: '8px',
      paddingRight: '8px',
      color: theme.palette.primary.main,
      fontSize: '11px'
    }
  },
  exportButton: {
    style: {
      height: '20px',
      position: 'absolute',
      zIndex: '10',
      left: '20px',
      bottom: '20px'
    }
  },
  exportPDF: {
    style: {
      height: '30px',
      position: 'absolute',
      zIndex: '10',
      left: '60px',
      bottom: '20px'
    }
  }
});

export function dateToString(date) {
  var month = '' + (date.getMonth() + 1);
  var day = '' + date.getDate();
  var year = date.getFullYear().toString();
  return year.slice(-2) + '_' + month + '_' + day;
}

export const DataTableComponent = ({
  data = [],
  columns,
  handleClick,
  loading = false,
  paginationPerPage = 10,
  paginationRowsPerPageOptions = [10, 20, 30, 40, 50],
  rowCount = 0,
  striped = true,
  showDownload = false,
  showPDF = true,
  ...otherProps
}) => {
  const location = useLocation();
  const theme = useTheme();
  const classes = useStyles();
  const customClasses = customStyles(theme);

  // const customNoDataComponent = (
  //   <div className={classes.noData}>No hay registros para mostrar</div>
  // );

  const customProgressComponent = <Loading />;

  const doFunction = (e) => {
    if (handleClick) {
      handleClick(e);
    }
  };

  const getFileTitle = () => {
    const fileTitle = location.pathname.split('/').pop();
    return fileTitle + '_' + dateToString(new Date());
  };

  const ExportButton = () => {
    return <img src={ExcelIcon} alt="" className={classes.exportButton} />;
  };

  const ExportElement = () => {
    return (
      <ExcelFile element={<ExportButton />} filename={getFileTitle()}>
        <ExcelSheet data={data} name="Data export">
          {columns.map((column, index) => (
            <ExcelColumn
              key={index}
              label={column.name}
              value={column.selector}
            />
          ))}
        </ExcelSheet>
      </ExcelFile>
    );
  };

  return (
    <Paper className={classes.tableContainer}>
      <DataTable
        onRowClicked={doFunction}
        columns={columns}
        data={data}
        customStyles={customClasses}
        noHeader
        pagination
        // paginationComponentOptions={{
        //   rowsPerPageText: 'Registros por página:',
        //   rangeSeparatorText: 'de'
        // }}
        paginationPerPage={paginationPerPage}
        paginationRowsPerPageOptions={paginationRowsPerPageOptions}
        striped
        persistTableHead
        // noDataComponent={customNoDataComponent}
        progressPending={loading}
        progressComponent={customProgressComponent}
        {...otherProps}
      />
      {/*<div>*/}
      {showDownload && data.length > 0 && <ExportElement />}
      {/*{showPDF && data.length > 0 && <ExportToPDF/>}*/}
      {/*</div>*/}
    </Paper>
  );
};

export default DataTableComponent;
