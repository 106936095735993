import request from '../api-connector';

export const getDealsService = ({ id, period_id }) => {
  const serviceDef = {
    url: 'deal/list',
    method: 'get',
    params: { id, period_id }
  };
  return request(serviceDef);
};

export const getStaffsSelectService = ({ id }) => {
  const serviceDef = {
    url: 'staff/list/select',
    method: 'get',
    params: { id }
  };
  return request(serviceDef);
};

export const addDealService = ({
  group_id,
  period_id,
  owner_id,
  area_id,
  staff_id,
  description
}) => {
  const serviceDef = {
    url: 'deal/create',
    method: 'post',
    params: {
      group_id,
      period_id,
      owner_id,
      area_id,
      staff_id,
      description
    }
  };
  return request(serviceDef);
};

export const updateDealService = ({
  id,
  period_id,
  owner_id,
  area_id,
  staff_id,
  description
}) => {
  const serviceDef = {
    url: 'deal/update',
    method: 'post',
    params: {
      id,
      period_id,
      owner_id,
      area_id,
      staff_id,
      description
    }
  };
  return request(serviceDef);
};

export const deleteDealService = ({ id }) => {
  const serviceDef = {
    url: 'deal/delete',
    method: 'post',
    params: { id }
  };
  return request(serviceDef);
};

export const updateRecordsService = ({ id, period_id, records }) => {
  const serviceDef = {
    url: 'deal-record/update',
    method: 'post',
    params: {
      id,
      period_id,
      records
    }
  };
  return request(serviceDef);
};

export const getEvaluationsService = ({ id, period_id }) => {
  const serviceDef = {
    url: 'evaluation/list',
    method: 'get',
    params: { id, period_id }
  };
  return request(serviceDef);
};
