/** Import react/libraries section **/
import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

/** Import resources section **/

/** Import helpers section **/
import { clearSession } from '../../store/slices/session-slice';

/** Import component section **/
import HeaderView from './HeaderView';
import Modal from '../../components/Modal';

export const HeaderComponent = () => {
  const dispatch = useDispatch();
  const [modalMode, setModalMode] = useState(null);

  let userName = useSelector((store) => store.session.profile?.name);

  const handleOnLogout = () => {
    setModalMode('logout');
  };
  const handleOnLogoutConfirm = () => {
    dispatch(clearSession());
  };
  const handleOnModalClose = () => {
    setModalMode(null);
  };
  return (
    <>
      <HeaderView userName={userName} onLogout={handleOnLogout} />
      {modalMode === 'logout' && (
        <Modal
          open={true}
          onClose={handleOnModalClose}
          onConfirm={handleOnLogoutConfirm}
          title="Cerrar Sesión"
        >
          {'¿Estas seguro que quieres salir?'}
        </Modal>
      )}
    </>
  );
};

export default HeaderComponent;
