/** Import react/libraries section **/
import React from 'react';

import makeStyles from '@mui/styles/makeStyles';

/** Import resources section **/
import LinearProgress from '@mui/material/LinearProgress';

/** Import component section **/

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%'
    // marginTop: '10px'
  }
}));

export const LoadingComponent = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <LinearProgress color="secondary" />
    </div>
  );
};

export default LoadingComponent;
