/** Import react/libraries section **/
import React, { useMemo } from 'react';

/** Import resources section **/
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import DeleteIcon from '@mui/icons-material/Delete';

/** Import helpers section **/
import './GroupStaffsStyles.scss';
import { GROUPS_ROUTE } from '../../config/URL_ROUTES';

/** Import component section **/
import PageTitle from '../../components/PageTitle/PageTitleComponent';
// import MUISelect from '../../components/inputs/MUISelect';
import FilterBy from '../../components/FilterBy/FilterBy';
import DataTable from '../../components/DataTable/DataTableComponent';
const GroupStaffsView = ({
  onFilterBy,
  loading,
  title,
  list,
  onAdd,
  onEdit,
  onDelete
}) => {
  const ActionsCell = ({ row }) => {
    return (
      <div className="g-actions-cell">
        <Divider orientation="vertical" flexItem />
        <DeleteIcon
          className="g-row-action-button delete"
          onClick={() => onDelete(row)}
        />
      </div>
    );
  };

  const tableColumns = useMemo(
    () => [
      {
        name: 'id',
        selector: (row) => row?.id,
        sortable: true,
        center: true,
        omit: true
      },
      {
        name: 'Nombre',
        selector: (row) => row?.staff?.user?.first_name,
        sortable: true,
        center: true
      },
      {
        name: 'Apellido',
        selector: (row) => row?.staff?.user?.last_name,
        sortable: true,
        center: true
      },
      {
        name: 'Email',
        selector: (row) => row?.staff?.user?.email,
        sortable: true,
        center: true
      },
      // {
      //   name: 'Área',
      //   selector: (row) => row?.title,
      //   sortable: true,
      //   center: true
      // },
      // {
      //   name: 'Compañía',
      //   selector: (row) => row?.company?.name,
      //   sortable: true,
      //   center: true
      // },
      {
        name: '',
        cell: (row) => <ActionsCell row={row} />,
        ignoreRowClick: true,
        grow: 0
      }
    ],
    []
  );
  console.log(list);
  return (
    <>
      <PageTitle title={'Grupo ' + title} backUrl={GROUPS_ROUTE} />
      <Paper className="g-page-header groupStaffs_header">
        <div className="groupStaffs_header_filters">
          <FilterBy onChange={onFilterBy} width={180} />
        </div>
        <Button onClick={onAdd} startIcon={<AddCircleIcon />}>
          Agregar colaborador
        </Button>
      </Paper>

      <DataTable data={list} columns={tableColumns} loading={loading} />
    </>
  );
};

export default GroupStaffsView;
