/** Import react/libraries section **/
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

/** Import helpers section **/
import {
  addAreasService,
  updateAreasService
} from '../../../api/services/areas-services';

/** Import component section **/
import AreaModalView from './AreaModalView';

const AreaModalComponent = ({ data, companies, onClose, onUpdateList }) => {
  const [area, setArea] = useState(data);
  const [dataComplete, setDataComplete] = useState(null);

  useEffect(() => {
    const newArea = Object.assign({}, data);
    setArea(newArea);
  }, [data]);

  const onInputChange = (data) => {
    const { name, value } = data;
    setArea((prevState) => ({
      ...prevState,
      [name]: value
    }));
  };

  useEffect(() => {
    if (!area) return;
    let validated = false;

    const title = area?.title?.trim();
    const companyId = area?.company_id;

    if (title && companyId) {
      validated = true;
    }

    setDataComplete(validated);
  }, [area]);

  const handleOnSave = () => {
    if (area.id) {
      updateArea();
    } else {
      createArea();
    }
  };

  const createArea = () => {
    addAreasService(area)
      .then((response) => {
        toast.success('Area creada');
        onUpdateList(response);
      })
      .catch((error) => {
        toast.error('No se pudo crear el área');
      });
  };

  const updateArea = () => {
    updateAreasService(area)
      .then((response) => {
        toast.success('Área actualizada');
        onUpdateList(response);
      })
      .catch((error) => {
        toast.error('No se pudo actualizar el área');
      });
  };

  return (
    <AreaModalView
      area={area}
      companies={companies}
      onInputChange={onInputChange}
      onClose={onClose}
      onSave={handleOnSave}
      disabled={!dataComplete}
    />
  );
};

export default AreaModalComponent;
