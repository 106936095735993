/** Import react/libraries section **/
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

/** Import helpers section **/

import {
  createStaffService,
  createMultipleStaffService,
  updateStaffService
} from '../../../api/services/signups-services';

import { getAreasSelectService } from '../../../api/services/areas-services';

/** Import component section **/
import SignUpsModalView from './SignUpsModalView';

const SignUpsModalComponent = ({
  modalMode,
  staffData,
  selectedRows,
  companies,
  onClose,
  onUpdateList
}) => {
  const [staff, setStaff] = useState(null);
  const [areas, setAreas] = useState(null);
  const [dataComplete, setDataComplete] = useState(null);
  const [modalSelectedRows, setModalSelectedRows] = useState(null);

  const getAreas = (companyId) => {
    if (!companyId) {
      setAreas([]);
      return;
    }
    getAreasSelectService({ company_id: companyId })
      .then((response) => {
        setAreas(response.areas);
      })
      .catch((err) => {
        if (err?.response_status) toast.error('No se pueden cargar las áreas');
        console.error('Error getting areas: ', err);
      });
  };

  useEffect(() => {
    if (modalMode === 'edit') {
      setStaff(staffData);
      if (staffData?.company_id) getAreas(staffData?.company_id);
    } else if (modalMode === 'multiple') {
      setModalSelectedRows(selectedRows);
    }
  }, [staffData, selectedRows, modalMode]);

  const handleOnInputChange = (data) => {
    const { name, value } = data;
    setStaff((prevState) => ({
      ...prevState,
      [name]: value
    }));
  };

  useEffect(() => {
    let validated = false;
    if (staff?.company_id && staff?.area_id) {
      validated = true;
    }
    setDataComplete(validated);
  }, [staff]);

  useEffect(() => {
    getAreas(staff?.company_id);
  }, [staff?.company_id]);

  const handleOnSave = () => {
    if (modalMode === 'edit') {
      if (staff.id) {
        updateStaff();
      } else {
        createStaff();
      }
    } else if (modalMode === 'multiple') {
      createMultipleStaff();
    }
  };

  const createStaff = () => {
    createStaffService(staff)
      .then((response) => {
        toast.success('Personal asignado');
        onUpdateList();
        onClose();
      })
      .catch((error) => {
        toast.error('Error al asignar personal');
      });
  };

  const updateStaff = () => {
    updateStaffService(staff)
      .then(() => {
        toast.success('Personal actualizado');
        onUpdateList();
        onClose();
      })
      .catch((error) => {
        toast.error('Error al actualizar personal');
      });
  };

  const createMultipleStaff = () => {
    createMultipleStaffService({
      staffs: modalSelectedRows,
      area_id: staff?.area_id
    })
      .then(() => {
        toast.success('Personal asignado');
        onUpdateList();
        onClose();
      })
      .catch((error) => {
        toast.error('Permission update error');
      });
  };

  const title =
    modalMode === 'multiple'
      ? 'Agregar multiples registros'
      : staff?.id
      ? 'Editar staff'
      : 'Asignar staff';

  return (
    <SignUpsModalView
      title={title}
      staff={staff}
      companies={companies}
      areas={areas}
      onInputChange={handleOnInputChange}
      onClose={onClose}
      onSave={handleOnSave}
      disabled={!dataComplete}
    />
  );
};

export default SignUpsModalComponent;
