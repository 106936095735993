import request from '../api-connector';

export const getSignupsService = ({ id }) => {
  const serviceDef = {
    url: 'staff/list',
    method: 'get',
    params: { id }
  };
  return request(serviceDef);
};

export const createStaffService = ({ area_id, company_id, user_id }) => {
  const serviceDef = {
    url: 'staff/create',
    method: 'post',
    params: { area_id, company_id, user_id }
  };
  return request(serviceDef);
};
export const createMultipleStaffService = ({ area_id, staffs }) => {
  const serviceDef = {
    url: 'staff/create/multiple',
    method: 'post',
    params: { area_id, staffs }
  };
  return request(serviceDef);
};

export const updateStaffService = ({ id, area_id, company_id, user_id }) => {
  const serviceDef = {
    url: 'staff/update',
    method: 'post',
    params: { id, area_id, company_id, user_id }
  };
  return request(serviceDef);
};

export const deleteStaffSignupService = ({ id }) => {
  const serviceDef = {
    url: 'staff/delete',
    method: 'post',
    params: {
      id
    }
  };
  return request(serviceDef);
};

export const validateSignupService = ({ id }) => {
  const serviceDef = {
    url: 'staff/update/active',
    method: 'post',
    params: {
      id
    }
  };
  return request(serviceDef);
};
export const changeRoleUserService = ({ id }) => {
  const serviceDef = {
    url: 'user/update/role',
    method: 'post',
    params: { id }
  };
  return request(serviceDef);
};
