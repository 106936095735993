/** Import react/libraries section **/
import React, { useState, useLayoutEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';

/** Import helpers section **/
import { setMenuOpen } from '../../store/slices/globals-slice';

/** Import styles section **/

/** Import resources section **/
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';
import CssBaseline from '@mui/material/CssBaseline';
import IconButton from '@mui/material/IconButton';
import MuiMenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import Divider from '@mui/material/Divider';

/** Import component section **/
import HeaderComponent from '../Header';
import MenuComponent from '../Menu';
import PrivateContent from '../PrivateContent';

const drawerWidth = 220;
const windowWidthMenuClose = 1000;

const openedMixin = (theme) => ({
  width: drawerWidth,
  backgroundColor: theme.palette.secondary.main,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen
  }),
  overflowX: 'hidden'
});

const closedMixin = (theme) => ({
  backgroundColor: theme.palette.secondary.main,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(9)} + 1px)`
  }
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open'
})(({ theme, open }) => ({
  paddingLeft: '10px',
  backgroundColor: theme.palette.secondary.main,
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin', 'padding'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen
  }),
  ...(open && {
    paddingLeft: '0',
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin', 'padding'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  })
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== 'open'
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme)
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme)
  })
}));

const MenuIcon = styled(MuiMenuIcon, {
  shouldForwardProp: (prop) => prop !== 'open'
})(({ theme, open }) => ({
  opacity: '0',
  transition: theme.transitions.create(['margin', 'opacity'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen
  }),
  ...(!open && {
    opacity: '100%',
    transition: theme.transitions.create(['margin', 'opacity'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  })
}));

const HeaderContainer = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'open'
})(({ theme, open }) => ({
  width: '100%',
  marginLeft: `-20px`,
  paddingLeft: `0`,
  transition: theme.transitions.create(['margin', 'padding'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen
  }),
  ...(!open && {
    marginLeft: `30px`,
    paddingLeft: `5px`,
    transition: theme.transitions.create(['margin', 'padding'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  })
}));

const PrivateLayoutComponent = () => {
  const theme = useTheme();
  const [open, setOpen] = useState(false);

  const dispatch = useDispatch();

  const handleDrawerOpen = useCallback(() => {
    setOpen(true);
    dispatch(setMenuOpen({ open: true }));
  }, [dispatch]);

  const handleDrawerClose = useCallback(() => {
    setOpen(false);
    dispatch(setMenuOpen({ open: false }));
  }, [dispatch]);

  useLayoutEffect(() => {
    function closeOnSizeUpdate() {
      if (window.innerWidth < windowWidthMenuClose) {
        handleDrawerClose();
      } else {
        handleDrawerOpen();
      }
    }
    window.addEventListener('resize', closeOnSizeUpdate);
    closeOnSizeUpdate();
    return () => window.removeEventListener('resize', closeOnSizeUpdate);
  }, [handleDrawerClose, handleDrawerOpen]);

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar position="fixed" open={open}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            disabled={open}
          >
            <MenuIcon open={open} />
          </IconButton>
          <HeaderContainer open={open}>
            <HeaderComponent />
          </HeaderContainer>
        </Toolbar>
      </AppBar>
      <Drawer variant="permanent" open={open}>
        <DrawerHeader>
          <IconButton onClick={handleDrawerClose} size="large">
            {theme.direction === 'rtl' ? (
              <ChevronRightIcon style={{ color: 'white' }} />
            ) : (
              <ChevronLeftIcon style={{ color: 'white' }} />
            )}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <MenuComponent />
      </Drawer>
      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        <DrawerHeader />
        <PrivateContent />
      </Box>
    </Box>
  );
};

export default PrivateLayoutComponent;
