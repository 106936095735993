/** Import react/libraries section **/
import React, { useRef } from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
} from 'chart.js';
import { Bar } from 'react-chartjs-2';

/** Import helpers section **/

/** Import resources section **/

/** Import syles section **/
import './GraphStyles.scss';

/** Import component section **/

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const GraphView = ({ title, values, labels, onGraphDownload }) => {
  const chartRef = useRef();

  const options = {
    scales: {
      y: {
        suggestedMax: 100
      },
      x: {
        ticks: {
          fontSize: 24
        },
        width: '200'
      },
      yAxes: [
        {
          afterFit: function (scaleInstance) {
            scaleInstance.width = 100; // sets the width to 100px
          }
        }
      ]
    },
    indexAxis: 'x',
    elements: {
      bar: {
        borderWidth: 2
      }
    },
    responsive: true,
    plugins: {
      legend: null,
      title: {
        display: true,
        text: title
      },
      tooltip: {
        display: false
      }
    }
  };

  const graphData = {
    labels: labels,
    datasets: [
      {
        data: values,
        // borderColor: 'rgb(255, 99, 132)',
        // backgroundColor: 'rgba(255, 99, 132, 0.5)'
        backgroundColor: [
          'rgba(120, 190, 32, 0.5)',
          'rgba(49, 66, 104, 0.5)',
          'rgba(153, 102, 255, 0.2)'
        ],
        borderColor: [
          'rgba(120, 190, 32, 1)',
          'rgba(49, 66, 104, 0.5)',
          'rgba(153, 102, 255, 1)'
        ],
        borderWidth: 1
      }
    ]
  };

  return (
    <div className="graph" onClick={() => onGraphDownload(chartRef)}>
      <Bar ref={chartRef} options={options} data={graphData} />
    </div>
  );
};

export default GraphView;
