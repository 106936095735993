/** Import react/libraries section **/
import React from 'react';

/** Import styles section **/
import './LogosStyles.scss';

/** Import resources section **/
import logoMediata from '../../assets/bwlogo_name.png';

export const LogosComponent = () => {
  return (
    <div className="logos">
      <div className="logos_mediata">
        <img src={logoMediata} alt="up-logo" className="logos_mediata_img" />
      </div>
    </div>
  );
};

export default LogosComponent;
