/** Import react/libraries section **/
import React, { useEffect, useState } from 'react';

import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';

export const MUISelect = ({
  id,
  label = '',
  name,
  labelId,
  defaultValue,
  items,
  idSelector,
  selector,
  noSelectedLabel = 'Seleccionar',
  onChange,
  readOnly = false,
  disabled = false,
  ...otherProps
}) => {
  const [value, setValue] = useState(-1);
  const inputLabelId = labelId !== null ? labelId : label + '__InputLabel';

  useEffect(() => {
    if (defaultValue && items) {
      const index = items.findIndex(
        (item) => item[idSelector] === defaultValue
      );
      const newValue = index > -1 ? (defaultValue ? defaultValue : -1) : -1;
      setValue(newValue);
    }
  }, [defaultValue, items, idSelector]);

  const handleOnSelect = (e) => {
    const newValue = e.target.value;
    setValue(newValue);

    if (onChange) {
      const returnedValue = newValue !== -1 ? newValue : null;
      onChange({ id: id, name: name, value: returnedValue });
    }
  };

  return (
    <FormControl id={'MUI_' + id}>
      <Select
        style={{}}
        id={id}
        labelId={inputLabelId}
        value={value}
        onChange={handleOnSelect}
        fullWidth
        inputProps={{ readOnly: readOnly }}
        disabled={disabled}
        {...otherProps}
      >
        <MenuItem value={-1}>
          <em>{noSelectedLabel}</em>
        </MenuItem>

        {items?.map((item, index) => (
          <MenuItem data-cy="menu-item" key={index} value={item[idSelector]}>
            {item[selector]}
          </MenuItem>
        ))}
      </Select>
      <InputLabel
        id={inputLabelId}
        style={{ backgroundColor: 'white', padding: '0 5px' }}
      >
        {label}
      </InputLabel>
    </FormControl>
  );
};

export default MUISelect;
