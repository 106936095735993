/** Import react/libraries section **/
import React, { useEffect, useState, useCallback } from 'react';
import { toast } from 'react-toastify';

/** Import helpers section **/
import {
  getStaffsSelectService,
  addDealService,
  updateDealService
} from '../../../api/services/deals-services';

/** Import component section **/
import DealModalView from './DealModalView';

const DealModalComponent = ({ data, groups, areas, onClose, onUpdateList }) => {
  const [deal, setDeal] = useState(data);
  const [dataComplete, setDataComplete] = useState(null);
  const [staffs, setStaffs] = useState([]);

  useEffect(() => {
    const newDeal = Object.assign({}, data);
    setDeal(newDeal);
  }, [data]);

  const getStaffs = useCallback(() => {
    if (!deal?.area_id) {
      setStaffs([]);
      return;
    }
    getStaffsSelectService({ id: deal?.area_id })
      .then((response) => {
        const staffsArr = response?.staffs;
        if (staffsArr) {
          setStaffs(staffsArr);
        }
      })
      .catch((error) => {
        console.error('Error requesting staffs list: ', error);
      });
  }, [deal?.area_id]);

  useEffect(() => {
    getStaffs();
  }, [deal?.area_id, getStaffs]);

  const onInputChange = (data) => {
    const { name, value } = data;
    setDeal((prevState) => ({
      ...prevState,
      [name]: value
    }));
  };

  useEffect(() => {
    if (!deal) return;
    let validated = false;

    const description = deal?.description?.toString().trim() || '';
    const areaId = deal?.area_id;
    const staffId = deal?.staff_id;

    if (description.length > 0 && areaId && staffId) {
      validated = true;
    }

    setDataComplete(validated);
  }, [deal]);

  const handleOnSave = () => {
    if (deal.id) {
      updateDeal();
    } else {
      createDeal();
    }
  };

  const createDeal = () => {
    addDealService(deal)
      .then((response) => {
        toast.success('Acuerdo creado');
        onUpdateList(response);
      })
      .catch((error) => {
        if (error.status === 409) {
          toast.error('El usuario no tiene compañía');
        } else {
          toast.error('No se pudo crear el acuerdo');
        }
      });
  };

  const updateDeal = () => {
    updateDealService(deal)
      .then((response) => {
        toast.success('Acuerdo actualizado');
        onUpdateList(response);
      })
      .catch((error) => {
        toast.error('No se pudo actualizar el acuerdo');
      });
  };

  return (
    <DealModalView
      deal={deal}
      areas={areas}
      staffs={staffs}
      onInputChange={onInputChange}
      onClose={onClose}
      onSave={handleOnSave}
      disabled={!dataComplete}
    />
  );
};

export default DealModalComponent;
