/** Import react/libraries section **/
import React, { useEffect, useState, useCallback } from 'react';
import { useHistory } from 'react-router-dom';

/** Import helpers section **/
import { toast } from 'react-toastify';

/** Import services section **/
import {
  getGroupsService,
  deleteGroupsService
} from '../../api/services/groups-services';
import { getCompaniesService } from '../../api/services/companies-services';

/** Import resources section **/
import { GROUP_STAFFS_ROUTE, PERIODS_ROUTE } from '../../config/URL_ROUTES';

/** Import component section **/
import Modal from '../../components/Modal/ModalComponent';
import GroupsView from './GroupsView';
import GroupsModal from './GroupModal';

const GroupModal = {
  id: null,
  title: '',
  company_id: ''
};

const GroupsComponent = () => {
  const [companies, setCompanies] = useState([]);
  const [selectedCompanyId, setSelectedCompanyId] = useState(null);
  const [originalData, setOriginalData] = useState([]);
  const [groups, setGroups] = useState([]);
  const [selectedGroup, setSelectedGroup] = useState(null);
  const [modalMode, setModalMode] = useState(null);
  const [loading, setLoading] = useState(false);

  const history = useHistory();

  const getCompanies = useCallback(() => {
    setLoading(true);
    getCompaniesService()
      .then((response) => {
        const companies = response?.companies;
        if (companies) {
          setCompanies(companies);
        }
      })
      .catch((error) => {
        console.error(
          'groups component - Error requesting groups list: ',
          error
        );
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const getGroups = useCallback((companyId) => {
    if (!companyId) return;
    setLoading(true);
    getGroupsService({ id: companyId })
      .then((response) => {
        const groups = response?.groups;
        if (groups) {
          setGroups(groups);
          setOriginalData(groups);
        }
      })
      .catch((error) => {
        console.error('Error requesting groups list: ', error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    getCompanies();
  }, [getCompanies]);

  const handleOnUpdateList = (selectedRows) => {
    getGroups(selectedCompanyId);
    handleOnModalClose();
  };

  const handleOnCompanyChange = (company) => {
    setSelectedCompanyId(company?.value);
    getGroups(company?.value);
  };

  const handleOnFilterBy = (filterVal) => {
    let filteredItems;

    if (!filterVal || filterVal.length === 0) {
      filteredItems = [...originalData];
    } else {
      const filteringText = filterVal?.toLowerCase();

      filteredItems = originalData.filter((row) => {
        const field1 = row?.title.toString().toLowerCase();

        return field1.includes(filteringText);
      });
    }

    setGroups(filteredItems);
  };

  const handleOnAdd = () => {
    setSelectedGroup(
      Object.assign(GroupModal, { company_id: selectedCompanyId })
    );
    setModalMode('group');
  };

  const handleOnEdit = (row) => {
    let item = Object.assign({}, row);
    setSelectedGroup(item);
    setModalMode('group');
  };

  const handleOnModalClose = () => {
    setSelectedGroup(null);
    setModalMode(null);
  };

  const handleOnDelete = (row) => {
    setSelectedGroup(row);
    setModalMode('delete');
  };

  const handleOnDeleteConfirm = () => {
    deleteGroupsService({ id: selectedGroup.id })
      .then(() => {
        getGroups();
        toast.success('Grupo borrado');
      })
      .catch((error) => {
        toast.error('Error, no se puede borrar el grupo.');
      })
      .finally(() => {
        setSelectedGroup(null);
        setModalMode(null);
      });
  };

  const handleOnStaff = (row) => {
    history.push({
      pathname: GROUP_STAFFS_ROUTE + '/' + row?.id,
      state: {
        id: row?.id,
        title: row?.title
      }
    });
  };
  const handleOnPeriods = (row) => {
    history.push({
      pathname: PERIODS_ROUTE + '/' + row?.id,
      state: {
        id: row?.id,
        title: row?.title
      }
    });
  };

  return (
    <>
      <GroupsView
        loading={loading}
        list={groups}
        companies={companies}
        selectedCompanyId={selectedCompanyId}
        onCompanyChange={handleOnCompanyChange}
        onFilterBy={handleOnFilterBy}
        onAdd={handleOnAdd}
        onStaff={handleOnStaff}
        onPeriods={handleOnPeriods}
        onEdit={handleOnEdit}
        onDelete={handleOnDelete}
      />
      {selectedGroup && modalMode === 'group' && (
        <GroupsModal
          data={selectedGroup}
          companies={companies}
          onClose={handleOnModalClose}
          onUpdateList={handleOnUpdateList}
        />
      )}
      {selectedGroup && modalMode === 'delete' && (
        <Modal
          open={true}
          onClose={handleOnModalClose}
          onConfirm={handleOnDeleteConfirm}
          title="Borrar grupo"
        >
          {'¿Estas seguro que quieres borrar el grupo ' +
            selectedGroup.title +
            '?'}
        </Modal>
      )}
    </>
  );
};

export default GroupsComponent;
