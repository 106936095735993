/** Import react/libraries section **/
import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

/** Import styles section **/
import './PageTitleStyles.scss';

/** Import resources section **/
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';

export const PageTitleComponent = ({ title, backUrl, children, className }) => {
  return (
    <div className={'pagetitle ' + (className ? className : '')}>
      <div className="pagetitle_title">
        {backUrl && (
          <Link to={backUrl} className="pagetitle_title_backicon">
            <NavigateBeforeIcon className="pagetitle_title_backicon_icon" />
          </Link>
        )}
        <span className="pagetitle_title_text">{title}</span>
      </div>
      {children}
    </div>
  );
};

PageTitleComponent.propTypes = {
  title: PropTypes.string.isRequired,
  backUrl: PropTypes.string
};

export default PageTitleComponent;
