/** Import react/libraries section **/
import React, { useEffect, useState, useCallback } from 'react';

/** Import helpers section **/
import { toast } from 'react-toastify';
import {
	getRolesService,
	getUsersService,
	getUserByIdService,
	deleteUserService,
	changeActiveService
} from '../../api/services/users-services';

/** Import resources section **/

/** Import component section **/
import Modal from '../../components/Modal';
import UserModal from './UserModal';
import UsersView from './UsersView';

const userModel = {
	id: null,
	first_name: '',
	last_name: '',
	email: '',
	password: '',
	password_conf: '',
	role_id: null,
	active: 1
};

const UsersComponent = () => {
	const [roles, setRoles] = useState([]);
	const [originalData, setOriginalData] = useState([]);
	const [users, setUsers] = useState([]);
	const [selectedUser, setSelectedUser] = useState(null);
	const [modalMode, setModalMode] = useState(null);
	const [loading, setLoading] = useState(false);

	const getRoles = useCallback(() => {
		getRolesService()
			.then((response) => {
				if (response?.roles) setRoles(response?.roles);
			})
			.catch((error) => {
				console.error('UserAddModal - Error getting: ', error);
			});
	}, []);

	const getUsers = useCallback(() => {
		setLoading(true);
		getUsersService()
			.then((response) => {
				setUsers(response?.users);
				setOriginalData(response?.users);
			})
			.catch((error) => {
				console.error(
					'UsersComponent - Error requesting users list: ',
					error
				);
			})
			.finally(() => {
				setLoading(false);
			});
	}, []);

	useEffect(() => {
		getRoles();
		getUsers();
	}, [getRoles, getUsers]);

	const handleOnUpdateList = (selectedUser) => {
		getUsers();
	};

	const handleOnFilterUsers = (filterVal) => {
		let filteredItems;

		if (!filterVal || filterVal.length === 0) {
			filteredItems = [...originalData];
		} else {
			const filteringText = filterVal?.toLowerCase();

			filteredItems = originalData.filter((item) => {
				const field1 = item.first_name?.toString().toLowerCase();
				const field2 = item.last_name?.toString().toLowerCase();
				const field3 = item.email?.toString().toLowerCase();
				const field4 = item.role_title?.toString().toLowerCase();

				return (
					field1.match(filteringText) ||
					field2.match(filteringText) ||
					field3.match(filteringText) ||
					(field4.match(filteringText) && true)
				);
			});
		}

		setUsers(filteredItems);
	};

	const handleOnAddUser = () => {
		setSelectedUser(Object.assign({}, userModel));
		setModalMode('user');
	};

	const getUserById = (id, mode) => {
		getUserByIdService({ id })
			.then((response) => {
				setSelectedUser(response?.user);
				setModalMode(mode);
			})
			.catch((error) => {
				console.error('UsersComp - Error fetching user data: ', error);
			});
	};

	const handleOnChangePassword = (row) => {
		getUserById(row.id, 'password');
	};

	const handleOnEditUser = (row) => {
		getUserById(row.id, 'user');
	};

	const handleOnModalClose = () => {
		setSelectedUser(null);
		setModalMode(null);
	};

	const handleOnDeleteUser = (row) => {
		setSelectedUser(row);
		setModalMode('delete');
	};

	const handleOnDeleteConfirm = () => {
		deleteUserService({ id: selectedUser.id })
			.then(() => {
				getUsers();
				toast.success('Usuario borrado');
			})
			.catch((error) => {
				toast.error('Error, no se puede borrar el usuario.');
			})
			.finally(() => {
				setSelectedUser(null);
				setModalMode(null);
			});
	};

	const handleOnActiveChange = (row) => {
		setSelectedUser(row);
		setModalMode('active');
	};

	const handleOnActiveConfirm = () => {
		changeActiveService({ id: selectedUser.id })
			.then(() => {
				getUsers();
				toast.success('Estatus cambiado');
			})
			.catch((error) => {
				toast.error('Error, no se pudo cambiar el estatus');
			})
			.finally(() => {
				setSelectedUser(null);
				setModalMode(null);
			});
	};

	return (
		<>
			<UsersView
				loading={loading}
				users={users}
				onFilterUsers={handleOnFilterUsers}
				onAddUser={handleOnAddUser}
				onEditUser={handleOnEditUser}
				onChangePassword={handleOnChangePassword}
				onDeleteUser={handleOnDeleteUser}
				onActiveUser={handleOnActiveChange}
			/>
			{selectedUser &&
				(modalMode === 'user' || modalMode === 'password') && (
					<UserModal
						userData={selectedUser}
						modalMode={modalMode}
						roles={roles}
						onClose={handleOnModalClose}
						onUpdateList={handleOnUpdateList}
					/>
				)}
			{selectedUser && modalMode === 'delete' && (
				<Modal
					open={true}
					onClose={handleOnModalClose}
					onConfirm={handleOnDeleteConfirm}
					title="Borrar Usuario"
				>
					{'¿Estas seguro que quieres borrar el usuario ' +
						selectedUser.email +
						'?'}
				</Modal>
			)}

			{selectedUser && modalMode === 'active' && (
				<Modal
					open={true}
					onClose={handleOnModalClose}
					onConfirm={handleOnActiveConfirm}
					title={
						selectedUser?.active === 0
							? 'Activar usuario?'
							: 'Desactivar usuario'
					}
				>
					{'¿Estas seguro que quieres ' +
						(selectedUser?.active === 0
							? 'Activar'
							: 'Desactivar') +
						' el usuario ' +
						selectedUser.email +
						'?'}
				</Modal>
			)}
		</>
	);
};

export default UsersComponent;
