import request from '../api-connector';

export const getDashboardService = ({ period_id }) => {
  const serviceDef = {
    url: 'dashboard/list',
    method: 'post',
    params: { period_id }
  };
  return request(serviceDef);
};

export const getGraphicService = ({ area_id, period_id, owner_id }) => {
  const serviceDef = {
    url: 'dashboard/graphics',
    method: 'post',
    params: {
      area_id,
      period_id,
      owner_id
    }
  };
  return request(serviceDef);
};
