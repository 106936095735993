/*********************************************************************
 ***********************  ROUTES DEFINITION  *************************
 *********************************************************************/

// Public routes
export const LOGIN_ROUTE = '/login';
export const REGISTRATION_ROUTE = '/registration';
export const EMAIL_VALIDATION_ROUTE = '/register-verify';
export const CHANGE_PASSWORD_REQUEST_ROUTE = '/change-password-request';
export const CHANGE_PASSWORD_ROUTE = '/change-password';

// Private routes
export const USERS_ROUTE = '/users';
export const SIGNUPS_ROUTE = '/signups';
export const ROLE_PERMISSIONS_ROUTE = '/rol-permissions';
export const PERMISSIONS_ROUTE = '/permissions';
export const COMPANIES_ROUTE = '/companies';
export const AREAS_ROUTE = '/areas';
export const GROUPS_ROUTE = '/groups';
export const GROUP_STAFFS_ROUTE = '/group-staffs';
export const PERIODS_ROUTE = '/periods';
export const DEALS_ROUTE = '/deals';
export const EVALUATIONS_ROUTE = '/evaluations';
export const DASHBOARD_ROUTE = '/dashboard';
