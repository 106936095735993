import * as routes from '../../config/URL_ROUTES';

/** Import resources section **/
import PersonIcon from '@mui/icons-material/Person';
import SettingsIcon from '@mui/icons-material/Settings';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import LockIcon from '@mui/icons-material/Lock';
import ApartmentIcon from '@mui/icons-material/Apartment';
// import SchemaIcon from '@mui/icons-material/Schema';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import GroupsIcon from '@mui/icons-material/Groups';
import ShareIcon from '@mui/icons-material/Share';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import DashboardIcon from '@mui/icons-material/Dashboard';

/*********************************************************************
 ************************  MENU ITEMS IDS  ***************************
 *********************************************************************/
const CONFIGURATION_MENU = 'configuration';
const PERMISSIONS_MENU = 'permissions';
const PERMISSIONS_CATALOGUE_MENU = 'permissions-catalogue';
const CATALOGUES_MENU = 'catalogues';
const USERS_MENU = 'users';
const SIGNUPS_MENU = 'signups';
const COMPANIES_MENU = 'companies';
const AREAS_MENU = 'areas';
const GROUPS_MENU = 'groups';
const DEALS_MENU = 'deals';
const EVALUATIONS_MENU = 'evaluations';
const DASHBOARD_MENU = 'dashboard';
/*********************************************************************
 **************************  MENU ITEMS  *****************************
 *********************************************************************/

const MENU_ITEMS = [
  {
    id: DASHBOARD_MENU,
    label: 'Tablero',
    url: routes.DASHBOARD_ROUTE,
    icon: DashboardIcon
  },
  {
    id: EVALUATIONS_MENU,
    label: 'Evaluaciones',
    url: routes.EVALUATIONS_ROUTE,
    icon: AssignmentTurnedInIcon
  },
  {
    id: DEALS_MENU,
    label: 'Acuerdos',
    url: routes.DEALS_ROUTE,
    icon: ShareIcon
  },

  {
    id: SIGNUPS_MENU,
    label: 'Registros',
    url: routes.SIGNUPS_ROUTE,
    icon: AssignmentIndIcon
  },

  {
    id: CATALOGUES_MENU,
    label: 'Catálogos',
    icon: SettingsIcon,
    items: [
      {
        id: USERS_MENU,
        label: 'Usuarios',
        url: routes.USERS_ROUTE,
        icon: PersonIcon
      },
      {
        id: GROUPS_MENU,
        label: 'Grupos',
        url: routes.GROUPS_ROUTE,
        icon: GroupsIcon
      },
      {
        id: AREAS_MENU,
        label: 'Areas',
        url: routes.AREAS_ROUTE,
        icon: AccountTreeIcon
      },
      {
        id: COMPANIES_MENU,
        label: 'Compañias',
        url: routes.COMPANIES_ROUTE,
        icon: ApartmentIcon
      }
    ]
  },
  {
    id: CONFIGURATION_MENU,
    label: 'Configuración',
    icon: SettingsIcon,
    items: [
      {
        id: PERMISSIONS_MENU,
        label: 'Permisos por rol',
        url: routes.ROLE_PERMISSIONS_ROUTE,
        icon: AccountTreeIcon
      },
      {
        id: PERMISSIONS_CATALOGUE_MENU,
        label: 'Permisos',
        url: routes.PERMISSIONS_ROUTE,
        icon: LockIcon
      }
    ]
  }
];
export default MENU_ITEMS;
