/** Import react/libraries section **/
import React from 'react';

/** Import helpers section **/

/** Import resources section **/

/** Import syles section **/
import './RecordModalStyles.scss';

/** Import component section **/
import ModalComponent from '../../../components/Modal/ModalComponent';

const recordValueArr = [0, 25, 50, 75, 100];

const RecordModalView = ({ records, onRecordChange, onClose, onSave }) => {
  const RecordValue = ({ seq, label, value }) => {
    const selected = label === value;
    return (
      <div
        className={'recordModal_record_item ' + (selected ? ' selected' : '')}
        onClick={() => onRecordChange(seq, label)}
      >
        {label + '%'}
      </div>
    );
  };
  const Record = ({ seq, value }) => {
    return (
      <div className="recordModal_record">
        <div className="recordModal_record_label">{'Semana ' + seq}</div>
        {recordValueArr.map((label, vIndex) => (
          <RecordValue
            key={'v_' + vIndex}
            seq={seq}
            label={label}
            value={value ? value : 0}
          />
        ))}
      </div>
    );
  };
  return (
    <ModalComponent
      open={true}
      title="Editar registros"
      onClose={onClose}
      onConfirm={onSave}
      // width={800}
    >
      <div className="recordModal">
        {records.map((record, index) => (
          <Record key={'s' + index} seq={index + 1} value={record?.value} />
        ))}
      </div>
    </ModalComponent>
  );
};

export default RecordModalView;
