/** Import react/libraries section **/
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, Redirect } from 'react-router-dom';

/** Import helpers section **/
import { toast } from 'react-toastify';

/** Import resources section **/
import { setSession } from '../../store/slices/session-slice';
import { setPermissions } from '../../store/slices/permissions-slice';

/** Import services section **/
import { loginService } from '../../api/services/session-services';

/** Import component section **/
import LoginView from './LoginView';

const LoginComponent = () => {
	let dispatch = useDispatch();
	let location = useLocation();

	let loggedIn = useSelector((store) => store.session.loggedIn);

	const [inProcess, setInProcess] = useState(false);
	const [enterApp, setEnterApp] = useState(false);

	useEffect(() => {
		if (loggedIn) setEnterApp(true);
	}, [loggedIn]);

	const login = ({ username, password }) => {
		setInProcess(true);
		loginService({ username, password })
			.then((response) => {
				let permissions = response?.permissions;
				const actions = permissions?.actions;
				const routes = permissions?.routes;
				const menus = permissions?.menus;

				setInProcess(false);
				dispatch(setPermissions({ actions, routes, menus }));

				let sessionInfo = {
					token: response?.token,
					role: response?.role,
					profile: {
						name: response?.first_name + ' ' + response?.last_name
					}
				};
				dispatch(setSession(sessionInfo));
			})
			.catch((err) => {
				setInProcess(false);
				const status = err.response_status;

				let message = '';
				switch (status) {
					case 401:
						message = 'Invalid user or password.';
						break;
					case 403:
						message = 'Usuario no activo';
						break;
					case 418:
						message =
							'Por favor contacta al administrador para habilitar tu acceso';
						break;
					case 422:
						message =
							'This user must be authorized. Please contact the administrator.';
						break;
					default:
						message = "Unexpected error, can't login";
						break;
				}

				if (status) toast.error(message);
				console.error('Error login: ', err);
			});
	};

	/**
	 * We receive submit event, call login service, on success we set token on localstorage and set session on store,
	 * else we set error and clean session.
	 * @param values
	 */
	const handleOnSubmit = ({ username, password }) => {
		login({ username, password });
	};

	if (enterApp) {
		return (
			<Redirect
				to={{
					pathname: '/',
					state: { from: location }
				}}
			/>
		);
	} else {
		return <LoginView inProcess={inProcess} onSubmit={handleOnSubmit} />;
	}
};

export default LoginComponent;
