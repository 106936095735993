import React, { forwardRef } from 'react';

import makeStyles from '@mui/styles/makeStyles';
import Checkbox from '@mui/material/Checkbox';

const useStyles = makeStyles((theme) => ({
  root: {
    color: theme.palette.normal,
    padding: '5',
    margin: 0
  },
  checkbox: {
    marginRight: '5px',
    transform: 'scale(1.3)'
  }
}));

export const MUICheckBox = forwardRef(
  ({ label, checked, onChange, ...otherProps }, ref) => {
    const classes = useStyles();

    const handleOnChange = (event) => {
      onChange(event.target.checked);
    };

    return (
      <Checkbox
        ref={ref}
        color="default"
        checked={checked}
        onChange={handleOnChange}
        className={classes.checkbox}
        {...otherProps}
      />
    );
  }
);

export default MUICheckBox;
