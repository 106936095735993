/** Import react/libraries section **/
import React, { useMemo } from 'react';

/** Import resources section **/
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import GroupIcon from '@mui/icons-material/Group';
import DateRangeIcon from '@mui/icons-material/DateRange';

/** Import helpers section **/
import './GroupsStyles.scss';

/** Import component section **/
import PageTitle from '../../components/PageTitle/PageTitleComponent';
import MUISelect from '../../components/inputs/MUISelect';
import FilterBy from '../../components/FilterBy/FilterBy';
import DataTable from '../../components/DataTable/DataTableComponent';
const GroupView = ({
  companies,
  selectedCompanyId,
  onCompanyChange,
  onFilterBy,
  loading,
  list,
  onAdd,
  onStaff,
  onPeriods,
  onEdit,
  onDelete
}) => {
  const ActionsCell = ({ row }) => {
    return (
      <div className="g-actions-cell">
        <GroupIcon
          className="g-row-action-button secondary"
          onClick={() => onStaff(row)}
        />
        <DateRangeIcon
          className="g-row-action-button complementary"
          onClick={() => onPeriods(row)}
        />
        <EditIcon className="g-row-action-button" onClick={() => onEdit(row)} />
        <Divider orientation="vertical" flexItem />
        <DeleteIcon
          className="g-row-action-button delete"
          onClick={() => onDelete(row)}
        />
      </div>
    );
  };

  const tableColumns = useMemo(
    () => [
      {
        name: 'id',
        selector: (row) => row?.id,
        sortable: true,
        center: true,
        omit: true
      },
      {
        name: 'Grupo',
        selector: (row) => row?.title,
        sortable: true,
        center: true
      },
      {
        name: 'Compañia',
        selector: (row) => row?.company?.name,
        sortable: true,
        center: true
      },
      {
        name: '',
        cell: (row) => <ActionsCell row={row} />,
        ignoreRowClick: true,
        width: '140px'
      }
    ],
    []
  );

  return (
    <>
      <PageTitle title="Grupos" />
      <Paper className="g-page-header groups_header">
        <div className="groups_header_filters">
          <MUISelect
            label="Compañía"
            defaultValue={selectedCompanyId}
            items={companies}
            idSelector="id"
            selector="name"
            name="company"
            id="company"
            onChange={onCompanyChange}
          />
          <FilterBy onChange={onFilterBy} width={180} />
        </div>
        <Button onClick={onAdd} startIcon={<AddCircleIcon />}>
          Agrega un nuevo grupo
        </Button>
      </Paper>

      <DataTable data={list} columns={tableColumns} loading={loading} />
    </>
  );
};

export default GroupView;
