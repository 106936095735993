/** Import react/libraries section **/
import React, { useEffect, useState, useCallback } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

/** Import helpers section **/
import { toast } from 'react-toastify';

/** Import services section **/
import {
  getGroupStaffsService,
  removeGroupStaffService
} from '../../api/services/group-staffs-services';

// import { getCompaniesService } from '../../api/services/companies-services';

/** Import resources section **/
import { GROUPS_ROUTE } from '../../config/URL_ROUTES';

/** Import component section **/
import Modal from '../../components/Modal/ModalComponent';
import StaffModal from './StaffModal';
import GroupStaffsView from './GroupStaffsView';

const groupStaffModel = {
  id: null,
  group_id: null,
  staff_id: null
};

const GroupStaffsComponent = () => {
  // const [companies, setCompanies] = useState([]);
  const [groupStaffs, setGroupStaffs] = useState([]);
  const [originalData, setOriginalData] = useState([]);

  const [selectedRow, setSelectedRow] = useState(null);
  const [modalMode, setModalMode] = useState(null);
  const [loading, setLoading] = useState(false);

  let history = useHistory();
  let locationState = useLocation().state;
  let title = useLocation().state?.title;

  console.log(locationState);

  if (!locationState?.id) {
    console.log('Se regresa a grupos');
    history.push(GROUPS_ROUTE);
  }

  const getStaffGroups = useCallback(() => {
    setLoading(true);
    getGroupStaffsService({ id: locationState?.id })
      .then((response) => {
        const staffs = response?.staffs;
        if (staffs) {
          setGroupStaffs(staffs);
          setOriginalData(staffs);
        }
      })
      .catch((error) => {
        console.error('Error requesting staffs list: ', error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [locationState?.id]);

  useEffect(() => {
    getStaffGroups();
  }, [getStaffGroups]);

  const handleOnUpdateList = () => {
    getStaffGroups();
    handleOnModalClose();
  };

  const handleOnFilterBy = (filterVal) => {
    let filteredItems;

    if (!filterVal || filterVal.length === 0) {
      filteredItems = [...originalData];
    } else {
      const filteringText = filterVal?.toLowerCase();

      filteredItems = originalData.filter((row) => {
        const field1 = row?.staff?.user?.first_name.toString().toLowerCase();
        const field2 = row?.staff?.user?.last_name.toString().toLowerCase();
        const field3 = row?.staff?.user?.email.toString().toLowerCase();

        return (
          field1.includes(filteringText) ||
          field2.includes(filteringText) ||
          field3.includes(filteringText)
        );
      });
    }

    setGroupStaffs(filteredItems);
  };

  const handleOnAdd = () => {
    setSelectedRow(
      Object.assign(groupStaffModel, { group_id: locationState?.id })
    );
    setModalMode('add');
  };

  const handleOnEdit = (row) => {
    let item = Object.assign({}, row);
    setSelectedRow(item);
    setModalMode('area');
  };

  const handleOnModalClose = () => {
    setSelectedRow(null);
    setModalMode(null);
  };

  const handleOnDelete = (row) => {
    setSelectedRow(row);
    setModalMode('delete');
  };

  const handleOnDeleteConfirm = () => {
    removeGroupStaffService({ id: selectedRow.id })
      .then(() => {
        handleOnUpdateList();
        toast.success('Borrado');
      })
      .catch((error) => {
        toast.error('Error, no se puede borrar el participante.');
      })
      .finally(() => {
        setSelectedRow(null);
        setModalMode(null);
      });
  };

  return (
    <>
      <GroupStaffsView
        loading={loading}
        list={groupStaffs}
        title={title}
        onFilterBy={handleOnFilterBy}
        onAdd={handleOnAdd}
        onEdit={handleOnEdit}
        onDelete={handleOnDelete}
      />
      {selectedRow && modalMode === 'add' && (
        <StaffModal
          data={selectedRow}
          onClose={handleOnModalClose}
          onUpdateList={handleOnUpdateList}
        />
      )}
      {selectedRow && modalMode === 'delete' && (
        <Modal
          open={true}
          onClose={handleOnModalClose}
          onConfirm={handleOnDeleteConfirm}
          title="Borrar participante"
        >
          {'¿Estas seguro que quieres borrar?'}
        </Modal>
      )}
    </>
  );
};

export default GroupStaffsComponent;
