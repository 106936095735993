import React from 'react';
import withStyles from '@mui/styles/withStyles';

import DialogActions from '@mui/material/DialogActions';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';

const styles = () => ({
  root: {
    backgroundColor: 'white',
    padding: '0 10px 0 10px'
  },
  actions: {
    marginTop: '0'
  },
  divider: {
    marginTop: '10px'
  }
});

const MUIDialogActions = withStyles(styles)((props) => {
  const {
    children,
    classes,
    onClose,
    onConfirm,
    cancelText,
    confirmText,
    disabled,
    ...other
  } = props;
  const handleOnConfirm = (event) => {
    onConfirm({
      preventDefault: () => {},
      persist: () => {}
    });
    event.preventDefault();
  };

  const handleOnClose = (event) => {
    onClose({
      preventDefault: () => {},
      persist: () => {}
    });
    event.preventDefault();
  };

  return (
    <div className={classes.root}>
      <Divider className={classes.divider} />
      <DialogActions className={classes.actions} {...other}>
        {onClose ? (
          <Button className="g-button cancel" onClick={handleOnClose}>
            {cancelText ? cancelText : 'Cancel'}
          </Button>
        ) : null}
        {onConfirm ? (
          <Button
            onClick={handleOnConfirm}
            type="submit"
            disabled={disabled ? disabled : false}
          >
            {confirmText ? confirmText : 'Ok'}
          </Button>
        ) : null}
      </DialogActions>
    </div>
  );
});

export default MUIDialogActions;
