/** Import react/libraries section **/
import React from 'react';

/** Import helpers section **/

/** Import resources section **/

/** Import syles section **/
import './PeriodModalStyles.scss';

/** Import component section **/
import ModalComponent from '../../../components/Modal/ModalComponent';
import MUIDateField from '../../../components/inputs/MUIDateField';

const PeriodModalView = ({
  period,
  onInputChange,
  onClose,
  onSave,
  disabled
}) => {
  return (
    <ModalComponent
      open={true}
      title={period?.id ? 'Editar periodo' : 'Agregar periodo'}
      onClose={onClose}
      onConfirm={onSave}
      disabled={disabled}
    >
      <div className="periodModal">
        <MUIDateField
          shouldDisableDate={(date) => date.getDay() !== 1}
          label="Fecha inicio"
          value={period?.start_date}
          name="start_date"
          onChange={onInputChange}
        />
        <MUIDateField
          shouldDisableDate={(date) => date.getDay() !== 5}
          label="Fecha fin"
          value={period?.end_date}
          minDate={period?.start_date ? new Date(period.start_date) : null}
          name="end_date"
          onChange={onInputChange}
        />
      </div>
    </ModalComponent>
  );
};

export default PeriodModalView;
