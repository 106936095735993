import request from '../api-connector';

export const getRolesService = () => {
  const serviceDef = {
    url: 'role/list',
    method: 'get',
    params: null
  };
  return request(serviceDef);
};

export const getUsersService = () => {
  const serviceDef = {
    url: 'user/list',
    method: 'get',
    params: null
  };
  return request(serviceDef);
};

export const getUserByIdService = ({ id }) => {
  const serviceDef = {
    url: 'user/read',
    method: 'post',
    params: { id }
  };
  return request(serviceDef);
};

export const addUserService = ({
  first_name,
  last_name,
  email,
  role_id,
  password,
  active
}) => {
  const serviceDef = {
    url: 'user/create',
    method: 'post',
    params: { first_name, last_name, email, role_id, password, active }
  };
  return request(serviceDef);
};

export const updateUserService = ({
  id,
  first_name,
  last_name,
  email,
  role_id,
  active
}) => {
  const serviceDef = {
    url: 'user/update',
    method: 'post',
    params: { id, first_name, last_name, email, role_id, active }
  };
  return request(serviceDef);
};

export const changePasswordService = ({ id, password }) => {
  const serviceDef = {
    url: 'user/update/password',
    method: 'post',
    params: {
      id,
      password
    }
  };
  return request(serviceDef);
};
export const changeActiveService = ({ id }) => {
  const serviceDef = {
    url: 'user/update/active',
    method: 'post',
    params: {
      id
    }
  };
  return request(serviceDef);
};

export const deleteUserService = ({ id }) => {
  const serviceDef = {
    url: 'user/delete',
    method: 'post',
    params: { id }
  };
  return request(serviceDef);
};
