/** Import react/libraries section **/
import React, { useEffect, useState, useCallback } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

/** Import helpers section **/
import { toast } from 'react-toastify';

/** Import services section **/
import {
  getPeriodsService,
  deletePeriodsService,
  changeActiveService
} from '../../api/services/periods-services';

/** Import resources section **/
import { GROUPS_ROUTE } from '../../config/URL_ROUTES';

/** Import component section **/
import Modal from '../../components/Modal/ModalComponent';
import PeriodModal from './PeriodModal';
import PeriodsView from './PeriodsView';

const PeriodsModel = {
  id: null,
  group_id: null,
  staff_id: null
};

const PeriodsComponent = () => {
  const [periods, setPeriods] = useState([]);
  const [selectedRow, setSelectedRow] = useState(null);
  const [modalMode, setModalMode] = useState(null);
  const [loading, setLoading] = useState(false);

  let history = useHistory();
  let locationState = useLocation().state;
  let title = useLocation().state?.title;

  if (!locationState?.id) {
    history.push(GROUPS_ROUTE);
  }

  const getPeriods = useCallback(() => {
    setLoading(true);
    getPeriodsService({ id: locationState?.id })
      .then((response) => {
        const period = response?.periods;
        if (period) {
          setPeriods(period);
        }
      })
      .catch((error) => {
        console.error('Error requesting areas list: ', error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [locationState?.id]);

  useEffect(() => {
    getPeriods();
  }, [getPeriods]);

  const handleOnUpdateList = () => {
    getPeriods();
    handleOnModalClose();
  };

  const handleOnAdd = () => {
    setSelectedRow(
      Object.assign(PeriodsModel, { group_id: locationState?.id })
    );
    setModalMode('period');
  };

  const handleOnEdit = (row) => {
    let item = Object.assign({}, row);
    setSelectedRow(item);
    setModalMode('period');
  };

  const handleOnModalClose = () => {
    setSelectedRow(null);
    setModalMode(null);
  };

  const handleOnDelete = (row) => {
    setSelectedRow(row);
    setModalMode('delete');
  };

  const handleOnDeleteConfirm = () => {
    deletePeriodsService({ id: selectedRow.id })
      .then(() => {
        handleOnUpdateList();
        toast.success('Borrado');
      })
      .catch((error) => {
        toast.error('Error, no se puede borrar el área.');
      })
      .finally(() => {
        setSelectedRow(null);
        setModalMode(null);
      });
  };

  const handleOnSwitchActive = (row) => {
    console.log('handleOnSwitchActive');
    setSelectedRow(row);
    setModalMode('active');
  };

  const handleOnActiveConfirm = () => {
    changeActiveService({ id: selectedRow.id })
      .then(() => {
        getPeriods();
        toast.success('Estatus cambiado');
      })
      .catch((error) => {
        toast.error('Error, no se pudo cambiar el estatus');
      })
      .finally(() => {
        setSelectedRow(null);
        setModalMode(null);
      });
  };

  return (
    <>
      <PeriodsView
        loading={loading}
        list={periods}
        title={title}
        onAdd={handleOnAdd}
        onEdit={handleOnEdit}
        onSwitchActive={handleOnSwitchActive}
        onDelete={handleOnDelete}
      />
      {selectedRow && modalMode === 'period' && (
        <PeriodModal
          data={selectedRow}
          onClose={handleOnModalClose}
          onUpdateList={handleOnUpdateList}
        />
      )}
      {selectedRow && modalMode === 'delete' && (
        <Modal
          open={true}
          onClose={handleOnModalClose}
          onConfirm={handleOnDeleteConfirm}
          title="Borrar Área"
        >
          {'¿Estas seguro que quieres borrar?'}
        </Modal>
      )}
      {selectedRow && modalMode === 'active' && (
        <Modal
          open={true}
          onClose={handleOnModalClose}
          onConfirm={handleOnActiveConfirm}
          title={
            !!selectedRow?.active ? 'Desactivar periodo' : 'Activar periodo'
          }
        >
          {'¿Estas seguro que quieres ' +
            (!!selectedRow?.active ? 'Desactivar' : 'Activar') +
            ' el periodo del ' +
            selectedRow.start_date +
            ' al ' +
            selectedRow.end_date +
            '?'}
        </Modal>
      )}
    </>
  );
};

export default PeriodsComponent;
