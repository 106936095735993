/** Import react/libraries section **/
import React, { useEffect, useState, useCallback } from 'react';

/** Import helpers section **/
import { toast } from 'react-toastify';

/** Import services section **/
import {
  getAreasService,
  deleteAreasService
} from '../../api/services/areas-services';
import { getCompaniesService } from '../../api/services/companies-services';

/** Import resources section **/

/** Import component section **/
import Modal from '../../components/Modal/ModalComponent';
import AreaModal from './AreaModal';
import AreaView from './AreasView';

const AreaModel = {
  id: null,
  title: '',
  company_id: ''
};

const AreasComponent = () => {
  const [companies, setCompanies] = useState([]);
  const [selectedCompanyId, setSelectedCompanyId] = useState(null);
  const [originalData, setOriginalData] = useState([]);
  const [areas, setAreas] = useState([]);
  const [selectedRow, setSelectedRow] = useState(null);
  const [modalMode, setModalMode] = useState(null);
  const [loading, setLoading] = useState(false);

  const getCompanies = useCallback(() => {
    setLoading(true);
    getCompaniesService()
      .then((response) => {
        const companies = response?.companies;
        if (companies) {
          setCompanies(companies);
        }
      })
      .catch((error) => {
        console.error('Error requesting companies list: ', error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const getAreas = useCallback((companyId) => {
    if (!companyId) return;
    setLoading(true);
    getAreasService({ id: companyId })
      .then((response) => {
        const areas = response?.areas;
        if (areas) {
          setAreas(areas);
          setOriginalData(areas);
        }
      })
      .catch((error) => {
        console.error('Error requesting areas list: ', error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    getCompanies();
  }, [getCompanies]);

  const handleOnUpdateList = () => {
    getAreas(selectedCompanyId);
    handleOnModalClose();
  };

  const handleOnCompanyChange = (company) => {
    setSelectedCompanyId(company?.value);
    getAreas(company?.value);
  };

  const handleOnFilterBy = (filterVal) => {
    let filteredItems;

    if (!filterVal || filterVal.length === 0) {
      filteredItems = [...originalData];
    } else {
      const filteringText = filterVal?.toLowerCase();

      filteredItems = originalData.filter((row) => {
        const field1 = row.title?.toString().toLowerCase();
        const field2 = row?.company?.name.toString().toLowerCase();

        return field1.includes(filteringText) || field2.includes(filteringText);
      });
    }

    setAreas(filteredItems);
  };

  const handleOnAdd = () => {
    setSelectedRow(Object.assign(AreaModel, { company_id: selectedCompanyId }));
    setModalMode('area');
  };

  const handleOnEdit = (row) => {
    let item = Object.assign({}, row);
    setSelectedRow(item);
    setModalMode('area');
  };

  const handleOnModalClose = () => {
    setSelectedRow(null);
    setModalMode(null);
  };

  const handleOnDelete = (row) => {
    setSelectedRow(row);
    setModalMode('delete');
  };

  const handleOnDeleteConfirm = () => {
    deleteAreasService({ id: selectedRow.id })
      .then(() => {
        getAreas(selectedCompanyId);
        toast.success('Área borrada');
      })
      .catch((error) => {
        toast.error('Error, no se puede borrar el área.');
      })
      .finally(() => {
        setSelectedRow(null);
        setModalMode(null);
      });
  };

  return (
    <>
      <AreaView
        loading={loading}
        list={areas}
        companies={companies}
        selectedCompanyId={selectedCompanyId}
        onCompanyChange={handleOnCompanyChange}
        onFilterBy={handleOnFilterBy}
        onAdd={handleOnAdd}
        onEdit={handleOnEdit}
        onDelete={handleOnDelete}
      />
      {selectedRow && modalMode === 'area' && (
        <AreaModal
          data={selectedRow}
          companies={companies}
          onClose={handleOnModalClose}
          onUpdateList={handleOnUpdateList}
        />
      )}
      {selectedRow && modalMode === 'delete' && (
        <Modal
          open={true}
          onClose={handleOnModalClose}
          onConfirm={handleOnDeleteConfirm}
          title="Borrar Área"
        >
          {'¿Estas seguro que quieres borrar el área ' +
            selectedRow.title +
            '?'}
        </Modal>
      )}
    </>
  );
};

export default AreasComponent;
