/** Import react/libraries section **/
import React from 'react';

/** Import helpers section **/

/** Import resources section **/

/** Import syles section **/
import './AreaModalStyles.scss';

/** Import component section **/
import ModalComponent from '../../../components/Modal/ModalComponent';
import MUITextField from '../../../components/inputs/MUITextField';
import MUISelect from '../../../components/inputs/MUISelect';

const AreaModalView = ({
  area,
  companies,
  onInputChange,
  onClose,
  onSave,
  disabled
}) => {
  return (
    <ModalComponent
      open={true}
      title={area?.id ? 'Editar área' : 'Agregar área'}
      element={area}
      onClose={onClose}
      onConfirm={onSave}
      disabled={disabled}
    >
      <div className="areamodal">
        <MUITextField
          id="title"
          label="Área"
          defaultValue={area?.title}
          type="text"
          name="title"
          onChange={onInputChange}
        />
        <MUISelect
          label="Compañía"
          defaultValue={area?.company_id}
          items={companies}
          idSelector="id"
          selector="name"
          noSelectedLabel="Select"
          name="company_id"
          id="company_id"
          onChange={onInputChange}
        />
      </div>
    </ModalComponent>
  );
};

export default AreaModalView;
