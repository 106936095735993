/** Import react/libraries section **/
import React from 'react';
import PropTypes from 'prop-types';

/** Import resources section **/
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';

/** Import styles section **/

/** Import helpers section **/
// import { color_primary, color_delete } from '../../styles/colors';

/** Import component section **/

// const iconStyle = {
// 	background: color_primary,
// 	color: 'white',
// 	width: '20px',
// 	height: '20px',
// 	borderRadius: '4px',
// 	padding: '4px',
// 	cursor: 'pointer',
// 	margin: '5px'
// };

// const iconDeleteStyle = {
// 	background: color_delete
// };

export const ActionsCellComponent = ({ item, onEdit, onDelete }) => {
	return (
		<>
			<div className="g-actions-cell">
				{onEdit && (
					<EditIcon
						className="g-row-action-button"
						onClick={() => onEdit(item)}
					/>
				)}
				{onDelete && (
					<DeleteIcon
						className="g-row-action-button delete"
						onClick={() => onDelete(item)}
					/>
				)}
			</div>
		</>
	);
};

ActionsCellComponent.propTypes = {
	item: PropTypes.object,
	onEdit: PropTypes.func,
	onDelete: PropTypes.func
};

export default ActionsCellComponent;
